.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    border: 1px solid palette(gray, border);
    border-top: 0;
    background: palette(white);
    box-shadow: -1px 1px 3px rgba(0,0,0,.1);

    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}
.autocomplete-suggestion {
    position: relative;
    padding: 0 .6em;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.02em;
    color: palette(black);

    &.selected {
        background: palette(gray, x-light);
    }

    b {
        font-weight: normal;
        color: palette(primary);
    }
}
