.expo-box__sponsor-logo {
    max-height: 36px;
}

.expo-box__logo {
    max-height: 77px;
}

.top-header__logo {
    max-height: 40px;
}

.header-powered-by {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1001;
}

.header-powered-by__link img, .powered-by__img {
    max-height: 35px;
}

.expo-banner {
    h4, .heading--like-h4, .like-h4 {
        min-width: 500px;

        @include screen(false, xxs) {
            min-width: 0;
        }
    }
}

.highlight-expo-box {
    @extend .expo-box;
    overflow: hidden;
    position: relative;
    padding: 20px 0;
    background: $white;

    .slide__nav {
        top: 40%;
    }

    .slide__nav--prev {
        left: 10px;
    }

    .highlight-section__thumbnail-wrapper {
        height: 300px;
    }
}

.common-header__heading span {
    font-size: 50%;
    display: block;
}

.channel-57 .header-powered-by__link img, .channel-57 .powered-by__img {
    max-height: 95px;
}
