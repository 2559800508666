.page-footer {
    background: $main-green;
    color: #FFF;

    .slide-top-section {
        display: none;
        text-align: center;
        padding: 7px 20px 9px;
        border-bottom: 1px solid #56ac30;

        @media screen and (max-width: 650px) {
            display: block;
        }

        a {
            color: #FFF;
            display: inline-flex;
            align-items: center;

            .ico {
                width: 14px;
                height: 8px;
                margin-right: 10px;
                fill: #fff;
                //position: relative;
                //top: -2px;
            }
        }
    }

    .nav-section {
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        background: $dark-green;

        @media screen and (max-width: 650px) {
            padding: 10px 20px 7px;
        }

        .common-section-inner {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: auto;
            min-height: 40px;
            margin: 0 -5px;
        }

        .nav-links {
            flex-grow: 1;
            display: flex;
            align-items: center;
            height: 100%;

            @media screen and (max-width: 1200px) {
                height: auto;
            }

            @media screen and (max-width: 650px) {
                float: none;
                order: 2;
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;
                margin-top: 10px;
            }

            a {
                display: inline-block;
                color: #FFF;
                padding: 5px 5px;
                margin-right: 3.8%;
                line-height: 1;
                white-space: nowrap;

                @media screen and (max-width: 1200px) {
                    margin-right: 10px;
                }

                @media screen and (max-width: 650px) {
                    flex: 0 0 divide(100%, 3);
                    margin: 0 0 10px;
                    text-align: center;
                }
            }
        }

        .sm-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            max-width: none;
            margin-left: auto;
            margin-right: -5px;

            @media screen and (max-width: 650px) {
                order: 1;
                justify-content: center;
                // width: 100%;
                flex-grow: 1;
                height: auto;
            }

            a {
                margin-left: 5px;
                margin-right: 5px;
            }

            .ico {
                vertical-align: middle;
                fill: #FFF;
            }
        }
    }

    .copyright-section {
        background: $main-green;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;

        .common-section-inner {
            overflow: hidden;
            display: flex;
            min-height: 90px;
            padding: 20px 0 23px;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 1200px) {
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-end;
                font-size: 14px;
                line-height: 1.36;
                padding: 20px 0 15px;
            }

            @media screen and (max-width: 650px) {
                justify-content: center;
                padding: 20px 0 18px;
            }
        }

        .tirage {
            width: 70%;
            float: left;
            align-items: center;
            display: flex;

            @media screen and (max-width: 1200px) {
                float: none;
                width: 100%;
                align-items: flex-end;
                margin-bottom: 10px;
            }

            @media screen and (max-width: 650px) {
                display: block;
                width: 100%;
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
                margin-bottom: 13px;
            }

            .lectura-logo {
                width: 127px;
                height: auto;
                margin-right: 6%;
                margin-bottom: -3px;

                @media screen and (max-width: 1200px) {
                    margin-right: 20px;
                    position: relative;
                    //top: -2px;
                    top: 9px;
                }

                @media screen and (max-width: 650px) {
                    margin: 6px auto 16px;
                }
            }

            .innerText {
                @media screen and (max-width: 650px) {
                    display: block;
                }

                .no-flexbox & {
                    display: inline-block;
                }
            }
        }

        .nav-links {
            width: 30%;
            float: right;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 -5px;

            @media screen and (max-width: 1200px) {
                float: none;
                text-align: left;
                width: 100%;
                justify-content: flex-start;
                margin-left: 143px;
            }

            @media screen and (max-width: 650px) {
                width: 100%;
                text-align: center;
                align-items: flex-start;
                //flex-wrap: wrap;
                margin-left: 0;
            }

            a {
                display: inline-block;
                color: #FFF;
                padding: 5px;
                text-decoration: underline;
                margin-left: 3.2%;

                @media screen and (max-width: 1200px) {
                    margin-left: 0;
                    margin-right: 10px;
                }

                @media screen and (max-width: 650px) {
                    width: 33.3%;
                    padding-top: 7px;
                    padding-bottom: 7px;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .siblings-section {
        background: $black;
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;

        .nav-links {
            display: flex;

            @media screen and (max-width: 650px) {
                flex-wrap: wrap;
                margin: 0 -1px;
                padding: 15px 0;
                overflow: hidden;
            }

            a {
                display: inline-block;
                //display: block;
                background: #373737;
                padding: 9px 15px 10px;
                color: #ccc;
                margin-right: 2px;

                .no-flexbox & {
                    margin-right: -2px;
                }

                @media screen and (max-width: 650px) {
                    display: block;
                    float: left;
                    width: 33.3%;
                    margin: 0;
                    border: 1px solid $black;
                    text-align: center;
                }
            }
        }
    }
}
