.news-column {
    @include column;
    @extend .column-4-of-16;

    @include screen(false, lg) {
        @extend .column-12-of-12;
        @include media-object;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 680px) {
        float: none;
        width: 100%;
        margin-bottom: 30px;
    }
}

.news-column__image {
    @include screen(false, lg) {
        @include media-object__side;
        margin-right: 20px;
    }

    @media screen and (max-width: 680px) {
        float: none;
        width: 100%;
        margin-bottom: 20px;
    }
}

.news-column__heading {
    color: palette(primary);
    border-top: 5px solid palette(primary);
    padding-top: 10px;
    padding-bottom: 20px;
    @include heading--like-h5;

    @include screen(false, lg) {
        padding: 10px 0;
    }

    @media screen and (max-width: 680px) {
        cursor: pointer;

        &:hover,
        &:focus {
            color: palette(primary, dark);
        }
    }
}

.news-column__content {

    .thumbnail__tag {
        font-size: 10px;
    }

    .thumbnail__title {
        font-size: 14px!important;
    }
    @media screen and (max-width: 680px) {
        overflow: hidden;
        max-height: 0;

        .js & {
            transition: max-height 0.3s ease;
            max-height: 36px;

        }
        .js-accordion.is-active & {
            max-height: 80em;
            overflow: visible;

            p {
                width: 100%;
                white-space: normal;
                overflow: visible;
                text-overflow: none;
            }
        }
    }
}

.news-column__heading-icon {
    display: none;
    @extend .common-header__icon;

    @media screen and (max-width: 680px) {
        display: inline-block;
        fill: palette(primary);
        width: 17px;
        height: 9px;
        top: -2px;
    }
}

.news-column__rows {
    @include screen(false, lg) {
        @include media-object__body;
    }

    @media screen and (max-width: 680px) {
        float: none;
        width: 100%;
    }
}

.news-column__row {
    padding: 15px 0;
    display: block;
    border-bottom: 1px solid palette(gray, light);
    color: $black;

    @include screen(false, lg) {
        padding: 11px 0;
        &:first-child {
            padding-top: 0;
        }
    }
}

.news-column__more-link {
    display: block;
    color: $black;
    font-weight: bold;
    padding: 15px 0 0;

    &:hover,
    &:focus {
        text-decoration: none;
        color: palette(primary);

        .news-column__icon {
            fill: palette(primary);
        }
    }

    span {
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.news-column__icon {
    @extend .common-header__icon;
    transition: fill 0.2s ease;
}
