.sm-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 245px;

    a {
        display: inline-block;
        padding: 5px 5px;

        @media screen and (max-width: 1200px) {
            margin-left: 10px;
        }

        @media screen and (max-width: 650px) {
            padding: 9px 10px;
        }
    }

    .ico {
        &.fb {
            width: 12px;
            height: 23px;
        }
        &.tw {
            width: 22px;
            height: 18px;
        }
        &.ig {
            width: 21px;
            height: 21px;
        }
        &.yt {
            width: 20px;
            height: 24px;
        }
        &.in {
            width: 21px;
            height: 20px;
        }
        &.whatsup {
            width: 22px;
            height: 22px;
        }
        &.mail {
            width: 21px;
            height: 17px;
        }
    }
}

.sm-link--color {
    .ico {
        &.fb {
            fill: #3B5998;
        }
        &.tw {
            fill: #55ACEE;
        }
        &.in {
            fill: #007BB6;
        }
    }
}

.sm-link__share-label {
    margin-bottom: 7px;
    color: palette(gray);

    @include screen(false, lg) {
        margin-bottom: 0;
    }
}
