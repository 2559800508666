// ==========================
// TYPOGRAPHY SCALE
// ==========================
//
// We should strive for consistency and enough contrast. That's why it is good
// idea to predefine set of font-sizes and stick with them as much as possible.
// Don't take it as a dogma, though. Breaking the rules is sometimes the best
// thing you can do. Just be sure it is really that case.
//
// 1. set base typography scale
// 2. prepare default style (h1, .heading--like-h1)
//    - include correct scale option and add other formatting
// 3. style heading with one simple class
//    - semantics is decoupled from presentation - you want h2 looks like h4?
//      No problem at all. Solution is just one class away.
// 4. for styling module elements, include either prepared class or mixin and
//    add more styles as needed
// 5. if you really need something special, write new rules or include existing
//    and override part of them.
//
// The sole purpose is to tame the type. The less unique styles, the better. We
// strive for strong scale and common styles, while allowing for exception when
// needed. The less exceptions, the cleaner look and easier maintanance in any
// project phase.
//
// The mixins are named after greek alphabet.
// alpha   - biggest
// beta
// gamma
// delta
// epsilon
// zeta    - smallest
//
// Based on:
// http://csswizardry.com/2012/02/pragmatic-practical-font-sizing-in-css/


// --------------------------
// Scale mixins
// --------------------------

// h1 size
@mixin heading--like-h1 {
    font-size: 48px;
    line-height: 1.25; // 60px
    margin: 0;

    @include screen(false, lg) {
        font-size: 36px;
        line-height: 42px;
    }

    @include screen(false, xxs) {
        font-size: 30px;
        line-height: 36px;
    }
}

// h2 size
@mixin heading--like-h2 {
    font-size: 32px;
    line-height: 1.111111111; // 40px
    margin: 0;

    @include screen(false, xxs) {
        font-size: 24px;
        line-height: 30px;
    }

    // podle navrhu `Press-article` upravuju
    // @media screen and (max-width: 600px) {
    //     font-size: 30px;
    //     line-height: 1.2;
    // }
}

// h3 size
@mixin heading--like-h3 {
    font-size: 30px;
    line-height: 1.2; // 36px
    margin: 0;
}

// h4 size
@mixin heading--like-h4 {
    font-size: 24px;
    line-height: 1.25; // 30px
    margin: 0;
}

// h5 size
@mixin heading--like-h5 {
    font-size: 21px;
    line-height: 1.285714286; // 27px
    margin: 0;
}

// h6 size
@mixin heading--like-h6 {
    font-size: 18px;
    line-height: 1.777777778; // 32px
    margin: 0;
}

@mixin heading--base {
    font-size: 16px;
    line-height: 1.3125; // 21px
}

// Smaller-than-body-copy sizes here.
// @mixin heading--milli {
//     //
// }

@mixin heading--micro {
    font-size: 12px;
    line-height: divide(13, 10); // 1.3
}

// @mixin caption {
//     @include heading--base;
//     //
// }

// @mixin perex {
//     @include heading--like-h3;
//     //
// }



// --------------------------
// Base typographic styles
// --------------------------

// Define your base font-size here; most elements will inherit this.
html {
    @include heading--base();
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

h1,
.heading--like-h1,
.like-h1 {
    @include heading--like-h1();
    font-weight: bold;
}

h2,
.heading--like-h2,
.like-h2 {
    @include heading--like-h2();
    font-weight: bold;
}

h3,
.heading--like-h3,
.like-h3 {
    @include heading--like-h3();
    font-weight: bold;
}

h4,
.heading--like-h4,
.like-h4 {
    @include heading--like-h4();
    font-weight: bold;
}

h5,
.heading--like-h5,
.like-h5 {
    @include heading--like-h5();
    font-weight: bold;
}

h6,
.heading--like-h6,
.like-h6 {
    @include heading--like-h6();
    margin-bottom: 0;
}

p {
    margin: 0 0 20px;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: palette(primary);
    text-decoration: none;

    &:hover,
    &:focus {
        color: $link-color;
        outline: none;
        transition: color 200ms;
        text-decoration: underline;
    }

    &:active {
        outline: none;
        color: $link-color--active;
    }

    &:visited {
        color: $link-color--visited;
    }

    .ico {
        width: 18px;
        height: 18px;
    }
}

ul {
    margin: 20px 0;
    list-style: none;
    padding: 0;

    ul {
        padding-left: 20px;
        margin: 0 0;
    }

    li {
        padding-left: 17px;
        position: relative;

        &:before {
            content: "";
            width: 7px;
            height: 7px;
            background: grey;
            border-radius: 50%;
            position: absolute;
            top: 10px;
            left: 0;
        }
    }
}

ol {
    margin: 16px 0;
    padding: 0;
    counter-reset: olCounter;

    > li {
        list-style-type: none;
        margin: 8px 0;
        padding: 0 0 0 40px;
        line-height: 1.5;
        position: relative;

        &::before {
            counter-increment: olCounter;
            content: counter(olCounter) ".";
            position: absolute;
            left: 0;
            top: -1px;
            font-size: 19px;
            width: 28px;
            text-align: right;
            font-weight: bold;
        }
    }
}

figure {
    margin: 0;
}
