.photo-thumbnails {
    @include clearfix;
    margin: 50px 10px 30px;

    @include screen(false, lg) {
        margin: 40px 5px 30px;
    }

    @include screen(false, xxs) {
        margin: 30px 3px 15px;
    }
}
