// ==========================
// Grid System
// ==========================
// - 12 equal columns, with 15px padding gutters on each side (gutter is
//   effectively 30px)
// - row has also 15px padding, which makes gutter on the sides of the row
//   also 30px
// - there is also row modification `.row--extended`
//   - it is moved 30px to the sides, in order to make more interesting layout
//   - columns are slighty bigger, gutters remain the same


// --------------------------
// Wrapper
// --------------------------
// Content container, centered. One place to control width of the content
// area.
// We control negative space around the edges, especially on narrow viewports
// with padding.
.wrapper {
    @include clearfix();
    max-width: $max-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    // padding: 0 $spacing;

    @include screen(false, xxxl) {
        padding:  0 20px;
    }

    @include screen(false, xxs) {
        padding: 0 10px;
    }
}

// --------------------------
// Row
// --------------------------
// Container, that clears floated columns inside.
// Also added 15px padding on the sides. It complements columns and basic site
// layout.

// 1 align with .row--extended, on small screen there is no space nor does it
//   look good to have this difference
.row {
    @include clearfix();
    margin: 0 -15px;
    // margin-bottom: divide($spacing, 2);

    @include screen(false, lg) {
        margin: 0 -10px;
    }

    @include screen(false, xxs) {
        margin: 0 -7.5px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

// --------------------------
// Columns
// --------------------------
// Common styling of every column.
//
// 1: in case you need absolute positioning of column's contents
// 2: prevent collapsing and weird rendering failures
// .column,
// [class^="column-"],
// [class*=" column-"] {
//     position: relative; // 1
//     display: block;
//     width: 100%;
//     float: left;
//     min-height: 1px; // 2
//     padding-right: 15px;
//     padding-left: 15px;
//     margin-bottom: 0;

//     @include screen(false, lg) {
//         padding-right: 10px;
//         padding-left: 10px;
//     }

//     @include screen(false, xxs) {
//         padding-right: 7.5px;
//         padding-left: 7.5px;
//     }
// }

@mixin column {
    position: relative; // 1
    display: block;
    float: left;
    min-height: 1px; // 2
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 0;


    @include screen(false, lg) {
        padding-right: 10px;
        padding-left: 10px;
    }

    @include screen(false, xxs) {
        padding-right: 7.5px;
        padding-left: 7.5px;
    }
}

.column {
    @include column;
    width: 100%;
}

// --------------------------
// Cycle for generating width. It loops twelve times, calculating proper width
// per each column size.
// --------------------------
// It generates standard column class, and `--large` modification. You can use
// that to choose column size based on large viewport.
//
// e.g.
// "<div class="column-6--small column-4--medium column-3--large>" will take
// half of the small screen, third on medium screen and quarter on large one.
@for $i from 1 through 16 {
    .column-#{$i}-of-16 {
        width: divide(100%, 16) * $i;
    }
}

// --------------------------
// Cycle for generating width for medium viewports.
// --------------------------
// It generates standard column class, and `--medium` modification. You can use
// that modification to choose specific size on medium viewport.
@include screen(false, lg) {
    @for $i from 1 through 12 {
        .column-#{$i}-of-12 {
            width: divide(100%, 12) * $i;
        }
    }
}

// --------------------------
// Cycle for generating width for small viewports.
// --------------------------
// Columns fill entire row, unless you choose `--small` modification. It
// overrides default setting.
@include screen(false, xxs) {
    @for $i from 1 through 6 {
        .column-#{$i}-of-6 {
            width: divide(100%, 6) * $i;
        }
    }
}

.layout__main-column {
    @include column;
    @extend .column-12-of-16;

    @include screen(false, xxl) {
        width: 100%;
    }
}

.layout__side-column {
    @include column;
    @extend .column-4-of-16;

    @include screen(false, xxl) {
        width: 100%;
    }
}
