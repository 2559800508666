.timeline-section {
    padding-top: 20px;

    .company-info__links li a {
        font-size: 14px;
    }

    .company-info__heading {
        font-size: 16px;
    }

    .company-info {
        padding: 15px 15px;
    }

    h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        line-height: 1.4em;
    }

    .top-reports-section h2, .timeline-big-videos h2 {
        font-size: 28px;
        line-height: 1.3em;
        width: 70%;
    }

    .top-reports-section h4, .timeline-big-videos h4 {
        width: 70%;
    }

    .video-thumbnail__section .thumbnail:before, .timeline-big-videos .thumbnail:before {
        display: none;
    }

    .timeline-big-videos h2 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .tabs--margin {
        margin-bottom: 20px;
    }

    .video-thumbnail__section--main {
        margin-top: 10px;
    }

    .timeline-videos-right {
        .thumbnail__wrapper {
            width: 100%;
            overflow: hidden;

            .thumbnail .thumbnail__image {
                height: 194px;
            }
        }
    } 

    .thumbnail {
        .thumbnail__image {
            height: 220px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .thumbnail--big {
        .thumbnail__image {
            height: 418px;
        }
    }

    .highlight-section__thumbnail-wrapper {
        height: 200px;
    }

    .layout__main-column .column-12-of-16 {
        width: 100%;
    }
    
    h4 {
        font-weight: 400;
        font-size: 20px;
    }
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;

    &::after {
        content: '';
        position: absolute;
        width: 4px;
        background-color: #71bb5d;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -3px;
    }

    .container {
        padding: 10px 0;
        position: relative;
        background-color: inherit;
        width: 50%;

		h2 {
            width: 100%;
        }

        &.right .read-more {
            right: 20px;
        }

        .read-button {
            position: absolute;
            bottom: 20px;
            left: 20px;

            &:after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .ico {
            width: 14px;
            height: 14px;
            padding-top: 4px;
            fill: #71bb5d;
        }

        .read-more {
            display: inline-block;
        }

        .read-less {
            display: none;
            background: #f6f6f6;
            min-width: 80px;
            text-align: center;
            padding: 5px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            right: -16px;
            background-color: #71bb5d;
            border: 4px solid #71bb5d;
            top: 15px;
            border-radius: 50%;
            z-index: 1;
            color: #fff;
            text-align: center;
            -moz-transition: border-radius .2s, width, .2s, height, .2s;
            -o-transition: border-radius .2s, width, .2s, height, .2s;
            -webkit-transition: border-radius .2s, width, .2s, height, .2s;
            transition: border-radius .2s, width, .2s, height, .2s;
        }

        &:hover::after {
            content: attr(data-date);
            width: 100px;
            height: 30px;
            border-radius: 0;
        }

        .content {
            .title {
                margin-bottom: 5px;
            }

            .date {
                font-size: 14px;
                color: #888;
                margin-bottom: 15px;
                display: inline-block;
            }

            .text-small {
                display: block;
            }

            .text-full {
                display: none;
            }
        }
    }

    .left {
        padding-left: 0;
        left: 0;
        text-align: left;

        &::before {
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            right: 30px;
            border: medium solid white;
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent white;
        }

        &::after {
            right: -12px;
        }

        &:hover::after {
            right: -50px;
        }
    }

    .right {
        left: 50%;
        padding-right: 0;
        text-align: right;

        &::before {
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            left: 30px;
            border: medium solid white;
            border-width: 10px 10px 10px 0;
            border-color: transparent white transparent transparent;
        }

        .read-button {
            left: auto;
            right: 0;
            bottom: 0;
        }

        &::after {
            left: -13px;
        }

        &:hover::after {
            left: -50px;
        }
    }

    .content {
        padding: 20px 0;
        position: relative;
        min-height: auto;
        margin-top: 15px;
    }

    .hidden {
        display:none !important;
    }

    .top-report-link {
        background: transparent;
        border-top: 0;

        .top-report-text {
            width: auto;
            padding: 0 20px;

            .title {
                color: #3DA755;
            }
        }
    }

    .container-open {
        .read-more {
            display: none;
        }

        .read-less {
            display: inline-block;
        }

        &.left .read-less {
            bottom: 0;
            left: 0;
        }

        .content {
            .text-full {
                display: block;
            }

            .text-small {
                display: none;
            }
        }

        .top-report-link {
            background-color: #f6f6f6;
            border-top: 5x solid #3DA755;
        } 
    }
}

.specs-info__button {
    background: #ffcd16;
    border-color: #c09a11;
    color: #c09a11;
    
    &:hover {
        background: #c09a11;
        border-color: #c09a11;
        color: #fff;
    }
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
    .timeline::after {
        left: 31px;
    }

    .container {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    .container::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }

    .left::after, .right::after {
        left: 15px;
    }

    .timeline {
        padding-right: 25px;

        .container {
            width: auto;
            padding-right: 20px;

            .read-button {
                left: auto !important;
                right: 20px !important;
            }
        }

        .left {
            left: 30px;

            &::after {
                left: -12px;
            }
        }

        .right {
            left: 30px;
            text-align: left;
        }
    }
}

.cross-timeline-section {
    padding-top: 20px;

    .timeline-box {
        text-align: center;

        h3 {
            font-size: 24px;
            margin-bottom: 25px;
        }

        .timeline-box__logo {
            margin: 15px 0;
            width: 100%;
            height: 30px;
            text-align: center; 

            img {
                max-height: 30px;
                max-width: 120px;
            }
        }
    }

    h2 {
        text-align: center;
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 1061px) {
    .timeline-section {
        .layout__main-column .column-12-of-16 {
            width: 75%;
        }
    }
}

@media screen and (max-width: 900px) {
    .timeline-section {
        .top-reports-section h2, .timeline-big-videos h2 {
            width: 100%;
        }

        .timeline-big-videos {
            .column-10-of-16 {
                width: 100%;
            }

            .column-6-of-16 {
                width: 100%;
            }

            .timeline-videos-right {
                margin-top: 20px;

                .thumbnail__wrapper {
                    width: 50%;
                    padding-left: 0;
                    padding-right: 20px;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .cross-timeline-section {
        .column-4-of-16 {
            width: 50%;
        }
    }
}

@media screen and (max-width: 400px) {
    .timeline-section {
        .timeline-big-videos .timeline-videos-right .thumbnail__wrapper {
            width: 100%;
            padding-right: 0;
        }
    }
    .cross-timeline-section {
        .column-4-of-16 {
            width: 100%;
        }
    }
}
