.survey-wrapper {
    h2 {
        text-align: center;
        margin: 20px 0;
    }

    h3 {
        text-align: center;
        margin: 10px;
        font-size: 26px;
    }

    h4 {
        margin-top: 20px;
        text-align: center;
    }

    table {
        margin: 15px 0;

        td, th {
            padding: 0 10px;
            text-align: left;
        }
    }

    .su-image {
        text-align: center;
    }

    .su-table-wrapper {
        max-width: 100%;
        overflow: hidden;
        overflow-x: scroll;
    }

    .side-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .su-image {
            //flex: 1;
            min-width: 300px;

            img {
                width: 360px;
            }
        }

        .su-table-wrapper {
            //flex: 2;
            //min-width: 600px;

            @media only screen and (max-width: 768px) {
                min-width: 100%;
            }
        }

        .su-text-wrapper {
            width: 75%;

            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &.compact {
            .su-image {
                flex: none;
                min-width: auto;
                margin-left: 30px;
                margin-top: 20px;

                @media only screen and (max-width: 768px) {
                    margin-left: 0;
                    margin-top: 0;
                    width: 100%;
                }

                img {
                    width: 200px;

                    @media only screen and (max-width: 768px) {
                        width: 75%;
                    }
                }
            }

            .su-table-wrapper {
                flex: none;
                min-width: auto;
            }
        }

        &.text {
            .su-image {
                margin-top: 0;
                flex: 1;

                @media only screen and (max-width: 768px) {
                    min-width: 200px;

                    img {
                        width: 300px;
                    }
                }
            }
        }

    }
}
