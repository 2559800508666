.article__external_content {
    background: $dark-green;
    color: $white;
    height: 80px;

    a {
        color: $white;
        text-decoration: none;

        &:hover .article__external_content__author {
            text-decoration: underline;
        }
        &:visited {
            color: $white;
        }
    }
}

.article__external_content__logo {
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
}

.article__external_content__author {
    float: left;
    padding: 28px 15px;
}

@include screen(false, xxs) {
    .article__external_content {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .article__external_content__logo {
        float: none;
    }

    .article__external_content__author {
        float: none;
        padding: 0;
    }
}

// DHF styles
.article__external_content.company_1612 {
    background: #d1003c;

    .article__external_content__logo {
        background-image: url('../img/tmp/walmart.png');
        width: 180px;
        height: 40px;
        margin-top: 19px;

        @include screen(false, xxs) {
            margin: 0;
        }
    }
}

// DHF styles
.article__external_content.company_1800 {
    background: #3d445b;

    .article__external_content__logo {
        background-image: url('../img/tmp/climmar-logo.png');
        width: 19px;
        height: 40px;
        margin-top: 19px;

        @include screen(false, xxs) {
            margin: 0;
        }
    }
}
