.header-powered-by {
    @include float-link;
    font-weight: normal;
    margin: 0;
    text-align: right;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.header-powered-by__label {
    color: palette(gray);
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 21px;
}
