//# Utilities
// Tailwind based

$spacer: 4px;

//## Layout
//### Columns
.xxs\:columns-2 {
    @include screen(xxs) {
        columns: 2;
    }
}
.xl\:columns-3 {
    @include screen(xl) {
        columns: 3;
    }
}
.xxxl\:columns-4 {
    @include screen(xxxl) {
        columns: 4;
    }
}

//### Display
// .block { display: block }
.inline-block { display: inline-block }
.flex { display: flex }

//## Flexbox & Grid
.flex-wrap { flex-wrap: wrap }

//## Spacing
//### Padding
// .p-0 { padding: 0 * $spacer }
// .px-0 {
//     padding-left: 0 * $spacer;
//     padding-right: 0 * $spacer;
// }
// .py-0 {
//     padding-top: 0 * $spacer;
//     padding-bottom: 0 * $spacer;
// }
// .pt-0 { padding-top: 0 * $spacer }
// .pr-0 { padding-right: 0 * $spacer }
// .pb-0 { padding-bottom: 0 * $spacer }
.pl-0 { padding-left: 0 * $spacer }

.py-1\.5 {
    padding-top: 1.5 * $spacer;
    padding-bottom: 1.5 * $spacer;
}
.p-2 {
    padding: 2 * $spacer;
}
.pt-6 {
    padding-top: 6 * $spacer;
}
.pt-8 {
    padding-top: 8 * $spacer;
}

//### Margin
.mt-0 {
    margin-top: 0 * $spacer;
}

.m-0\.5 {
    margin: 0.5 * $spacer;
}
.-mx-0\.5 {
    margin-left: -0.5 * $spacer;
    margin-right: -0.5 * $spacer;
}

.mt-3 {
    margin-top: 3 * $spacer;
}

.mb-8 {
    margin-bottom: 8 * $spacer;
}

//## Sizing

.w-10 {
    width: 10 * $spacer;
}

.min-w-\[36px\] {
    min-width: 36px;
}

//## Typography
//### List Style Type
// .list-none { list-style-type: none }
// .list-disc { list-style-type: disc }
// .list-decimal { list-style-type: decimal }

//## Text Align
// .text-left { text-align: left }
.text-center { text-align: center }
// .text-right { text-align: right }

//### Content
.before\:content-none:before { content: none }

//## Backgrounds
// background-color: $dark-gray;
// background-color: $border-gray;
// background-color: $medium-gray;
// background-color: $light-gray;
.bg-gray {
    background-color: $background-gray;
}
