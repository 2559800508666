.news-column-section {
    @include clearfix;
    padding: 50px 0 0;

    @include screen(false, lg) {
        padding-top: 40px;
    }

    @include screen(false, xxs) {
        padding-top: 25px;
    }


    .row {
        display: flex;
        flex-wrap: wrap;

        .news-column {
            min-width: 20%;
            margin-bottom: 25px;
        }
    }
}
