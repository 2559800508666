.news-section {
    @include clearfix;
    padding: 50px 0 0;

    @include screen(false, lg) {
        padding-top: 40px;
    }

    @include screen(false, xxs) {
        padding-top: 25px;
    }

    .thumbnail__wrapper {
        &.without-mobile-thumbnail {
            @media screen and (max-width: 680px) {
                .thumbnail {
                    border-bottom: 1px solid palette(gray, light);
                    padding-bottom: 10px;
                }

                .thumbnail:before {
                    content: none;
                }

                .thumbnail__tag {
                    @include heading--base;
                    color: $black;
                    display: inline;
                    font-weight: bold;
                    background: none;
                    position: relative;
                    text-transform: none;
                    padding: 0;
                    top: 0;
                    left: 0;

                    &:after {
                        content: ":";
                    }
                }

                .thumbnail__image {
                    display: none;
                }

                .over-info {
                    display: none;
                }

                .thumbnail__title {
                    @include heading--base;
                    color: $black;
                    display: inline;
                    font-weight: normal;
                    position: relative;
                    padding: 0;
                }
            }
        }
    }
}

.news-section--text {
    .thumbnail__wrapper--text {
        @include screen(false, lg) {
            &:nth-child(3n+4) {
                clear: left;
            }
        }

        @media screen and (max-width: 680px) {
            margin-bottom: 20px;

            .thumbnail {
                border-bottom: 0;
                padding-bottom: 0;
            }

            .thumbnail__image {
                display: block;
            }

            .thumbnail__tag {
                @include tag;
                background: rgba(149, 148, 148, 0.63);
                //top: 10px;
                //left: 13px;
                //position: absolute;

                &:after {
                    content: none;
                }
            }
        }
    }
}

.news-section__more-link {
    font-weight: bold;
    margin: 0 auto;
    color: $black;
    display: block;
    text-align: center;
    max-width: 200px;

    &:hover,
    &:focus {
        color: $black;
    }

    &:visited {
        color: $black;
    }
}
