.our-team-section {
    margin: 43px 0 0;

    @media screen and (max-width: 600px) {
        margin-top: 28px;
    }

    .sectiom-header {
        text-align: center;
        margin-bottom: 24px;

        @media screen and (max-width: 600px) {
            margin-bottom: 29px;
        }
    }


    .section-cols {
        width: 100%;
        max-width: 705px;
        margin: 0 auto;
        overflow: hidden;

        .section-col {
            width: 33.3%;
            float: left;
            padding: 0 10px;

            @media screen and (max-width: 600px) {
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
                float: none;
            }
        }
    }

    .team-item {
        text-align: center;
        font-weight: bold;

        @media screen and (max-width: 600px) {
            margin-bottom: 18px;
        }

        .team-photo {
            display: block;
            margin: 0 auto 9px;
        }

        .team-name {
            font-weight: bold;
            margin-bottom: 2px;

            @media screen and (max-width: 600px) {
                margin-bottom: -3px;
            }
        }

        .team-email {
            font-weight: bold;
        }
    }
}
