.tabs__wrapper {
    position: relative;

    // 1 - dropdown has absolute position so it doesn't push content when opening
    //     to prevent overlaping collapsed drowpdown we use this margin, which
    //     has same height as collapsed dropdown
    //
    // 2 - modification to fit context. It is used in brand-news template
    @include screen(false, xxs) {
        margin-bottom: 43px; // 1

        .layout__main-column & {
            margin-bottom: 50px; // 2
        }
    }
}

.tabs {
    border-bottom: 5px solid palette(gray, light);
    margin: 0;

    @include screen(false, xxs) {
        border: 2px solid palette(primary);
    }

    + .news-section {
        padding-top: 30px;
    }

    @include screen(false, xxs) {
        position: absolute;
        left: 0;
        right: 0;
        background: $white;
        z-index: 8;

        &.is-open {
            .tabs__tab {
                display: block;
            }

            .tabs__tab.is-active .tabs__link {
                background: url('../img/ico/arrow-up-green.svg') no-repeat 95% 50%;
                border-bottom: 2px solid palette(primary);
            }
        }
    }
}

.tabs--margin {
    margin-bottom: 45px;
}

.tabs__tab {
    padding-left: 0;
    display: inline-block;

    @include screen(false, xxs) {
        width: 100%;
        display: none;
    }

    &:before {
        content: none;
    }

    @include screen(false, xxs) {
        &.is-active {
            display: block;
        }
    }

    &.is-active .tabs__link{
        background: palette(gray, light);
        color: $black;

        @include screen(false, xxs) {
            color: palette(primary);
            background-color: $white;
            background: url('../img/ico/arrow-down-green.svg') no-repeat 95% 50%;
        }
    }
}

.tabs__link {
    font-weight: bold;
    display: inline-block;
    padding: 13px 15px 11px;
    color: palette(primary);

    @include screen(false, xxs) {
        padding: 8px 12px 7px;
        width: 100%;
    }

    &:visited {
        color: palette(primary);
    }
}
