.article__wrapper {
    .article__close-icon__wrapper {
        top: 192px;
    }

    .slide__nav {
        width: 75px;
        height: 75px;
        position: fixed;

        .slide__nav-arrow {
            width: 22px;
            height: 36px;
            margin-top: -18px;
            margin-left: -11px;
        }
    }

    .article__header {
        &.article__video {
            .article__header-link {
                bottom: 50px;
                right: 15px;
            }
        }

        .article__header-inner img, .article__header-inner picture {
            max-height: 640px;
        }

        .article__header-inner {
            position: relative;

            picture {
                width:fit-content;
                position: relative;
            }

            .article__header-link {
                right: 15px;
                bottom: 10px;
                width: fit-content;
            }
        }
    }

    .article__content {
        table {
            width: auto;
            height: auto;
            margin: 20px auto;

            th, td {
                padding: 10px;
                border: 1px solid palette(gray, light);
            }
        }

        li {
            display: flex;
            flex-direction: column;
        }
    }

    .article__source {
        color: palette(gray, base);
    }

    h1.article-header-one {
        max-width: 880px;
        margin: auto auto 50px;
        padding-top: 50px;
    }

    @media (max-width: 1024px) {
        h1.article-header-one {
            padding: 0 65px;
        }
    }
}
