.more-link-mobile {
    display: none;
    @include float-link;

    @include screen(false, xxs) {
        display: block;
        float: none;
        text-align: center;
        margin-top: 20px;
    }
}
