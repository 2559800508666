.article__header-inner img, .article__header-inner picture {
    width: auto;
}

.news-column__image.smaller {
    .thumbnail__title {
        font-size: 16px;
    }
}

.article__header-video-wrapper {
    text-align: center;
}