.photo-thumbnail__wrapper {
    @include column;
    padding: 0 5px;
    width: 25%;
    margin-bottom: 10px;

    @include screen(false, lg) {
        width: 33.3%;
        padding: 0 5px;
    }

    @include screen(false, xxs) {
        width: 50%;
        padding: 0 5px;
    }

    @media screen and (max-width: 400px) {
        width: 100%;

        &:last-child {
            margin-bottom: 30px;
        }
    }
}

.photo-thumbnail {
    display: block;
    width: 100%;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.photo-thumbnail__tag {
    @include tag;
    background: hsla(0,0%,58%,.63);
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: bold;
}
