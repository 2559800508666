.logos {
    @include clearfix();
    border-top: 5px solid palette(gray, light);
    padding-top: 50px;
    margin: 50px 15px 0;

    @media screen and (max-width: 1000px) {
        margin-top: 40px;
        padding-top: 30px;
    }

    @include screen(false, lg) {
        text-align: center;
        margin-bottom: 0;
    }

    @include screen(false, xxs) {
        margin: 30px 7px 0;
    }
}

.logos--stripe {
    border-top: none;
    padding-bottom: 45px;
    margin-top: 0;

    @include screen(false, lg) {
        padding-bottom: 30px;
        margin-bottom: 0;
    }
}

.logos__image-wrapper {
    @include column;
    text-align: center;
    width: 14.285714286%;

    @include screen(false, lg) {
        display: inline-block;
        width: 24%;
        float: none;
        vertical-align: top;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            margin-bottom: 25px;
        }
    }

    @include screen(false, xxs) {
        width: 50%;
        float: left;
        margin-bottom: 25px;

        &:nth-child(2n+3) {
            clear: left;
        }

        &:last-child,
        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }
}
