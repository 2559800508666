.disable-hover,
.disable-hover * {
    pointer-events: none !important;
}

.relative-container {
    position: relative;
}

.text-align-center {
    text-align: center;
}

.text-align-right{
    text-align: right;
}

.font-size-small {
    font-size: 12px;
}

.center,
.text-align-center {
    text-align: center;
}

.u-hide-mobile {
    @media screen and (max-width: 555px) {
        display: none;
    }
}

.u-hide-tablet {
    @include screen(false, lg) {
        display: none;
    }
}
