a:visited {
    color: $green;
}

.page-top-navigation a:visited {
    color: $white;
}

.news-column__more-link:visited, .news-column__row:visited {
    color: $black;
}