.expo-banner {
    @include clearfix;
    background: palette(gray, x-light);
    border-top: 5px solid $main-green;
    padding: 30px 25px;
    margin: 0 15px;

    @include screen(false, xxs) {
        padding: 15px;
        // margin: 0 -10px;
    }
}

.expo-banner__item {
    @include media-object;
    float: left;

    @include screen(false, xxs) {
        width: 100%;
    }
}

.expo-banner__heading {
    @include screen(false, xxs) {
        @include heading--base;
    }
}

.expo-banner__logo {
    @include media-object__side;
    max-width: 90px;
    @include screen(false, xxs) {
        width: 100%;
        text-align: center;
        float: none;
        margin: 0 auto 10px;
        display: block;
    }
}

.expo-banner__content {
    @include media-object__body;
    @include screen(false, xxs) {
        width: 100%;
        text-align: center;
    }
}

.expo-banner__meta {
    margin-bottom: 15px;
    color: palette(gray);
    @include screen(false, xxs) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
}


.expo-banner__more-link {
    font-weight: bold;
    color: $black;
    display: inline-block;
    margin-top: 20px;
    float: right;

    &:visited {
        color: $black;
    }
}
