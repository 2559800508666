.about-lectura-section {
    margin-top: 45px;

    @include screen(false, lg) {
        margin-top: 32px;
    }

    .section-inner {
        border-bottom: 5px solid $border-gray;
        padding-bottom: 47px;

        @include screen(false, lg) {
            padding-bottom: 14px;
        }

        @media screen and (max-width: 600px) {
            padding-bottom: 26px;
        }
    }

    .section-header {
        text-align: center;

        h1 {
            margin: 0;

            @include screen(false, lg) {
                font-size: 48px;
                margin-top: 4px;
            }

            @media screen and (max-width: 600px) {
                font-size: 36px;
                margin-top: 1px;
                margin-bottom: 10px;
            }
        }

        p {
            margin: 0 0 45px;

            @include screen(false, lg) {
                margin-bottom: 35px;
            }

            @media screen and (max-width: 600px) {
                margin-bottom: 22px;
            }
        }

        h2 {
            margin: 0 0 28px;
        }
    }

    .section-cols {
        @include clearfix();

        @include screen(false, lg) {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
        }

        .section-col {
            float: left;
            width: 25%;
            padding: 0 10px;

            @media screen and (max-width: 1000px) {
                width: 50%;
                margin-bottom: 20px;
            }

            @media screen and (max-width: 600px) {
                width: auto;
                float: none;
                margin-bottom: 14px;
            }
        }
    }

    .about-item {
        text-align: center;

        @include screen(false, lg) {
                margin-bottom: 5px;
        }

        .symbol {
            height: 48px;
            margin-bottom: 9px;

            @media screen and (max-width: 600px) {
                margin-bottom: 6px;
            }

            .ico {
                display: block;
                margin: 0 auto;
            }

            .newspaper {
                width: 55px;
                height: 38px;
            }

            .megaphone {
                width: 48px;
                height: 42px;
            }

            .earth {
                width: 42px;
                height: 42px;
                margin-top: 2px;
            }

            .people {
                width: 52px;
                height: 48px;
                margin-top: -2px;
            }

            + .about-item-title {
                @media screen and (max-width: 600px) {
                    font-size: 21px;
                }
            }

            ~ .about-item-text {
                @media screen and (max-width: 600px) {
                    margin-bottom: 32px;
                }
            }
        }

        .about-item-title {
            font-size: 30px;
            font-weight: bold;
            line-height: 1.2;
            margin-bottom: 7px;

            @media screen and (max-width: 600px) {
                margin-bottom: 3px;
            }
        }

        .about-item-text {
            line-height: 1.3;
        }
    }
}
