.screen-reader-text {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    padding: 0;
    margin: -1px;
    border: 0;
    clip: rect(0 0 0 0);
}
