.top-reports-section {
    margin: 30px 0 0;

    @media screen and (max-width: 1000px) {
        margin: 20px 0 0;
    }

    @media screen and (max-width: 760px) {
        margin: 10px 0 0;
    }

    .section-cols {
        overflow: hidden;
        margin: 0 -15px;

        @media screen and (max-width: 1000px) {
            margin: 0 -10px;
        }

        .section-col {
            float: left;
            width: 50%;
            padding: 0 15px;

            @media screen and (max-width: 1000px) {
                padding: 0 10px;
            }

            @media screen and (max-width: 600px) {
                float: none;
                width: auto;

                &:last-child {
                    display: none;
                }
            }
        }
    }

    .top-report-link {
        margin: 20px 0;
        display: block;
        text-decoration: none;
        color: $black;
        position: relative;
        overflow: hidden;
        background-color: $background-gray;
        border-top: 5px solid $main-green;
        padding: 26px 30px;
        min-height: 230px;

        @media screen and (max-width: 1000px) {
            padding-top: 29px;
            padding-right: 40px;
            padding-left: 40px;
        }

        @media screen and (max-width: 760px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .top-report-text {
            width: 50%;
            display: block;

            @media screen and (max-width: 1000px) {
                width: auto;
                min-height: 170px;
            }

            @media screen and (max-width: 600px) {
                min-height: auto;
            }

            .title {
                display: block;
                font-size: 30px;
                font-weight: bold;
                line-height: 1.2;
                margin-bottom: 14px;

                @media screen and (max-width: 1000px) {
                    margin-bottom: 17px;
                }
            }

            .text {
                display: block;
                font-size: 16px; //default font-size
                line-height: 1.31;
            }
        }

        .top-report-image {
            display: block;
            position: absolute;
            top: 23px;
            right: 30px;
            padding: 4px;
            border: 1px solid $border-gray;

            @media screen and (max-width: 1000px) {
                position: static;
                max-width: 220px;
                margin: 23px auto -115px;
            }

            @media screen and (max-width: 760px) {
                margin: 43px auto -115px;
            }
        }

        &:hover,
        &:focus {
            .top-report-text {
                .title {
                    color: $green;
                }
            }
        }
    }
}
