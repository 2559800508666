.reports-section {
    margin: 10px 0 0;

    @media screen and (max-width: 760px) {
        margin-top: 2px;
    }

    .section-cols {
        overflow: hidden;
        margin: 0 -15px;

        @media screen and (max-width: 1000px) {
            margin: 0 -10px;
        }

        .section-col {
            float: left;
            width: 25%;
            padding: 0 15px;

            @media screen and (max-width: 1000px) {
                width: 33.3%;
                padding: 0 10px;
            }

            @media screen and (max-width: 760px) {
                width: 50%;
            }
        }
    }

    .report-link {
        margin: 20px 0 0;
        display: block;
        text-decoration: none;
        color: $black;
        position: relative;

        @media screen and (max-width: 1000px) {
            margin-top: 8px;
        }

        .report-image {
            display: block;
            padding: 4px;
            border: 1px solid $border-gray;
            margin-bottom: 12px;

            @media screen and (max-width: 760px) {
                padding: 2px;
                margin-bottom: 13px;
            }

            img {
                width: 100%;
                display: block;
            }
        }

        .report-text {
            display: block;
            min-height: 82px;

            @media screen and (max-width: 760px) {
                min-height: 62px;
            }

            .title {
                display: block;
                font-size: 21px;
                font-weight: bold;
                line-height: 1.29;

                @media screen and (max-width: 760px) {
                    font-size: 16px;
                }
            }

            .text {
                display: block;
                font-size: 16px; //default font-size
                line-height: 1.31;
            }
        }

        &:hover,
        &:focus {
            .report-text {
                .title {
                    color: $green;
                }
            }
        }
    }
}

.dm-section {
    .flex-row {
        display: flex;

        .layout__main-column {
            flex: 1;
        }

        .layout__side-column {
            width: 312px;

            .company-info {
                display: block;

                @media (max-width: 996px) {
                    display: flex;
                    justify-content: center;
                }

                @media (max-width: 525px) {
                    flex-wrap: wrap;
                }
            }

            @media (max-width: 996px) {
               width: 100%;
            }
        }

        @media (max-width: 996px) {
            flex-wrap: wrap;
        }
    }

    .dm-person {
        display: block;
        overflow: hidden;
        margin-bottom: 10px;

        p {
            font-size: 15px;
            margin-bottom: 0;

            &.title {
                font-weight: bold;
            }
            &.name {
                margin-bottom: 10px;
            }
        }

        .info {

            picture {
                display: inline-block;
                float: left;
                margin-right: 10px;

                img {
                    width: 100px;
                }

                &.more {
                    @media (max-width: 996px) {
                        margin-top: 10px;
                    }
                }

                @media (max-width: 525px) {
                    min-width: 100px;
                }
            }

            .right {
                display: inline-block;
                width: 130px;
                float: left;

                p {
                    font-size: 13px;

                    &.connect-header {
                        margin-top: 18px;
                        margin-bottom: 10px;

                        &.more {
                            margin-top: 28px;

                            @media (max-width: 996px) {
                                margin-top: 38px;
                            }
                        }
                    }
                }
                .connect {
                    a {
                        margin-right: 10px;

                        svg {
                            width: 25px;
                        }
                    }
                }

                @media (max-width: 525px) {
                    width: auto;
                }
            }

            @media (max-width: 655px) {
                display: flex;
            }
        }

        @media (max-width: 525px) {
            min-width: 100%;
        }
    }

    .media-pack {
        p {
            margin-bottom: 0;

            &.here {
                font-size: 14px;
            }
        }
    }

    .tabs--margin {
        margin-bottom: 25px;
    }

    .latest-issue {
        .main-block {
            display: flex;

            picture {
                width: 300px;

                img {
                    margin-top: -17px;
                }

                @media (max-width: 700px) {
                    width: 100%;
                    text-align: center;
                }
            }
            div.info {
                flex: 1;
                margin-left: 15px;

                @media (max-width: 700px) {
                    margin-left: 0;
                }
            }

            @media (max-width: 700px) {
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
        }

        .button-newsletter.first-button {
            margin-right: 25px;
            margin-bottom: 15px;
        }

        .in-next {
            margin-top: 25px;
            margin-bottom: 25px;

            div {
                p {
                    margin: 0;
                }
            }

            h2 {
                font-size: 25px;
            }

            h3 {
                margin-top: 15px;
                font-size: 18px;
                color: #3da755;
            }
        }
    }

    .digimessenger.subscribe {
        display: flex;
        margin-top: 0;
        align-items: center;

        .subscribe__text {
            flex: 1;
            max-width: none;
            text-align: left;

            @media (max-width: 1060px) {
                min-width: 100%;
                text-align: center;
            }
        }

        .subscribe__form {
            display: flex;
            flex-direction: column;
            width: 575px;

            .top {
                input {
                    width: 300px;
                    float: left;
                    height: 45px;
                    border: 1px solid #3da755;

                    &.error {
                        border: 1px solid red;
                    }

                    @media (max-width: 655px) {
                        width: 100%;
                    }
                }

                .subscribe__or {
                    width: auto;
                    float: left;
                    clear: none;
                }

                @media (max-width: 655px) {
                    .subscribe__or, a {
                        float: left;
                    }
                }

                @media (max-width: 405px) {
                    .subscribe__or {
                        margin-top: 20px;
                    }

                    a:last-child {
                        margin-top: 10px;
                    }
                }
            }

            .concern {
                margin-top: 10px;
                p {
                    font-size: 12px;
                    margin: 0 0 5px;
                    text-align: left;

                    &.error {
                        input, label {
                            border-bottom: 1px solid red;
                        }
                    }

                    input {
                        margin-top: 2px;
                        display: block;
                        float: left;
                        margin-right: 10px;
                    }
                }
            }

            .success-message {
                display: none;
            }

            &.success {
                .top {
                    display: none;
                }

                .concern {
                    display: none;
                }

                .success-message {
                    display: block;
                }
            }
        }

        @media (max-width: 1060px) {
            flex-wrap: wrap;
        }
    }

    .dm-item {
        margin-top: 35px;

        h2 {
            font-size: 25px;
            margin-bottom: 25px;
        }

        .content {
            display: flex;

            picture {
                width: 275px;

                @media (max-width: 600px) {
                    a.desktop-only {
                        display: none;
                    }
                }

                img {}
            }

            .parallax-banner {
                display: none;
            }

            .info {
                flex: 1;
                margin-left: 25px;

                .info-content {
                    p {
                        margin: 0;
                    }
                }
                h3 {
                    margin-top: 15px;
                    font-size: 18px;
                    color: #3da755;
                }
                a {
                    margin-top: 25px;
                    display: none;

                    @media (max-width: 600px) {
                        &.mobile-only {
                            display: inline-block;
                        }
                    }
                }
            }

            @media (max-width: 600px) {
                flex-direction: column;

                picture {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
