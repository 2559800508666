.subscribe {
    @include clearfix;
    background: palette(gray, x-light);
    border-top: 5px solid palette(gray, light);
    margin-top: 50px;
    padding: 24px 30px 29px;

    @include screen(false, lg) {
        margin-top: 40px;
    }

    @include screen(false, xxs) {
        padding: 20px 15px;
        margin-top: 30px;
    }
}

.subscribe__text {
    @include heading--like-h5;
    font-weight: bold;
    max-width: 42%;
    float: left;
    padding-right: 20px;

    @include screen(false, xxxl) {
        max-width: none;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
}

input.subscribe__input {
    float: left;
    width: auto;
    padding: 9px 15px 10px;
    line-height: 25px;
    margin-bottom: 0;
    max-width: 210px;
    min-height: 46px;

    @media screen and (max-width: 400px) {
        width: 100%;
        min-width: 100%;
    }
}

.subscribe__cta-wrapper {
    display: inline;

    @include screen(false, lg) {
        display: inline-block;
    }
}

.subscribe__cta {
    float: left;

    @media screen and (max-width: 400px) {
        width: 100%;
    }
}

.subscribe__or {
    float: left;
    margin: 9px 15px 10px;
    line-height: 25px;

    @include screen(false, lg) {
        width: 100%;
        float: none;
        clear: both;
    }

    @include screen(false, xxs) {
        width: auto;
    }
}

.subscribe__secondary-button {
    float: left;
    line-height: 27px;

    @include screen(false, lg) {
        float: none;
    }
}

.subscribe__form {
    @include clearfix;
    // vertical-align: middle;
    float: right;

    @include screen(false, xxxl) {
        float: none;
        display: block;
        width: auto;
        margin: 0 auto;
        text-align: center;
        max-width: 660px;
    }
}
