.article-banner {
  text-align: center;
  margin-bottom: 1em;
}

.article-bigger-banner {
  float: right;
  margin: 0 -6em 0 2em;

  &.skyscraper {
    div.parallax-banner {
      display: none;
      margin: 0 auto;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      clip: rect(0px auto auto 0px);

      .parallax-background {
        background-size: cover!important;
        height: 100vh;
        width: 100%;
        left: 0;
        top: 0;
        position: fixed;
        animation: ios-clipping-hack .1s infinite;
      }

      @media (max-width: 640px) {
        display: block;
      }
    }

    picture {
      @media (max-width: 640px) {
        display: none;
      }
    }
    @media (max-width: 640px) {
      a {
        display: block;
        overflow: hidden;
        position: relative;
        height: 75.0vh;
        box-shadow: 0 0 7px 1px #666;
      }
    }
  }
}

@keyframes ios-clipping-hack {
  from {
    top: 0
  }
  to {
    top: 0.01px;
  }
}

.article-smaller-banner {
  float: left;
  margin: 0 2em 0 -6em;
}

.article-bigger-banner, .article-smaller-banner {
  & > span {
    font-size: 0.7em;
    color: #999999;
    display: block;
    text-align: right;
    text-transform: uppercase;
  }

  figure {
    margin: 0;
  }
}

@include screen(false, xxxl) {
  .article-bigger-banner {
    margin: 0 0 0 2em;
  }

  .article-smaller-banner {
    margin: 0 2em 0 0;
  }
}

@include screen(false, xs) {
  .article-bigger-banner, .article-smaller-banner {
    float: none;
    display: block;
    margin: 1em 0;
    clear: both;
    text-align: center;
  }

  .article-bigger-banner {
    width: 100vw;
    margin-left: -10px;
  }
}
