.about-lectura-section {
    .section-cols-3 {
        .section-col {
            width: 33%;

            @include screen(false, xxs) {
                width: 100%;
            }
        }
    }
}

.our-team-section {
    .section-cols {
        text-align: center;

        .section-col {
            width: 30%;
            float: none;
            display: inline-block;
            vertical-align: top;

            @include screen(false, xxs) {
                width: 100%;
            }
        }
    }
}
