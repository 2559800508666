.share-box {
    background: palette(gray, x-light);
    border-top: 5px solid palette(orange);
    padding: 23px 20px 18px;
    position: relative;

    @include screen(false, xxs) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.share-box__download {
    font-weight: bold;
    position: absolute;
    right: 30px;
    top: 27px;

    @include screen(false, lg) {
        top: 35px;
    }
}
