.company-info {
    @include clearfix;
    background: palette(gray, x-light);
    padding: 25px 20px 30px;
    border-top: 5px solid palette(primary);

    @include screen(false, xxl) {
        margin-bottom: 30px;
    }
}

.company-info--show-large {
    @include screen(false, xxl) {
        display: none;
    }
}

.company-info--show-medium {
    display: none;

    @include screen(false, xxl) {
        display: block;
    }

    @media screen and (max-width: 680px) {
        display: none;
    }
}

.company-info--show-small {
    @media screen and (max-width: 680px) {
        display: block;
    }
}

.company-info__row {
    @include screen(false, xxl) {
        margin: 0 -15px;
    }
}

.company-info__block.premium {
    .company-info__row {
        p {
            &.title {
                font-weight: bold;

                &.head {
                    margin-bottom: 0;
                }
            }

            &.info {
                line-height: 20px;
            }
        }
    }

    &.styled {
        background: #f6f6f6;
        display: flex;
        flex-direction: row-reverse;
        padding: 30px 65px;

        .company-logo {
            max-width: 30%;

            @media (max-width: 1060px) {
               img {
                   width: 100%;
               }
            }
        }

        .company-info__row {
            flex: 1;
        }

        @media (max-width: 600px) {
            padding: 30px 25px;

            .company-logo {
                max-width: 40%;
            }
        }
    }
}

.company-info__block.premium.company-53 {
    .mail {
        font-size: 14px;
    }
}

.company-info__logo {
    @media (max-width: 1060px) {
        float: right;
        max-width: 40vw;
    }
}

.company-info__col {
    @include screen(false, xxl) {
        width: 33.3%;
        float: left;
        padding: 0 15px;
    }

    @media screen and (max-width: 680px) {
        width: 100%;
        float: none;
    }
}

.company-info__logo {
    margin: 0 auto 20px;
    display: block;

    @include screen(false, xxl) {
        margin-left: 0;
        margin-right: 0;
    }

    @include screen(false, xxs) {
        margin-left: auto;
        margin-right: auto;
    }
}

.company-info__person {
    @include flag;
    margin-bottom: 30px;
    margin-top: 12px;
}

.company-info__person-image-wrapper {
    @include flag__side;
    margin-right: 15px;
}

.company-info__person-content {
    @include flag__body;

    a {
        font-weight: bold;

        &:visited {
            color: palette(primary);
        }
    }
}

.company-info__heading {
    @include heading--like-h5;
}

.company-info__links {
    list-style-type: none;
    margin: 0;

    li {
        padding-left: 0;
        margin-top: 10px;

        &:before {
            content: none;
        }

        a {
            font-weight: bold;
        }
    }
}

.company-info__thumbnails {
    list-style-type: none;
    margin: 0;
    margin: 30px 0 0;

    li {
        display: inline-block;
        padding-left: 0;
        padding-right: 7px;

        &:last-child {
            padding-right: 0;
        }

        &:before {
            content: none;
        }
    }
}

.company-info__cta {
    margin-top: 12px;
}

.company-info__button {
    margin-top: 15px;
}
