.video-button {
    @include transition();
    @include transition-property(background);
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    left: 50%;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    background: palette(black, transparent);
    z-index: 5;

    &:after {
        content: '';
        position: absolute;
        left: 30px;
        top: 23px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 17px 0 17px 28px;
        border-color: $transparent $transparent $transparent #fff;
    }
}

.video-button--large {
    margin-top: -70px;
    margin-left: -70px;
    width: 140px;
    height: 140px;
    border-radius: 50%;

    @include screen(false, lg) {
        width: 70px;
        height: 70px;
        margin-top: -35px;
        margin-left: -35px;
    }

    &:after {
        left: 54px;
        top: 40px;
        width: 0;
        height: 0;
        border-width: 28px 0 28px 46px;

        @include screen(false, lg) {
            left: 27px;
            top: 20px;
            border-width: 14px 0 14px 23px;
        }
    }
}


.video-button--small {
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    @include screen(false, lg) {
        width: 35px;
        height: 35px;
        margin-top: -17px;
        margin-left: -17px;
    }

    &:after {
        left: 20px;
        top: 14px;
        width: 0;
        height: 0;
        border-width: 11px 0 11px 16px;

        @include screen(false, lg) {
            left: 13px;
            top: 10px;
            border-width: 8px 0 8px 13px;
        }
    }
}
