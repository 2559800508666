.author-page {
  .section-inner {
    display: flex;
    padding-top: 30px;
    margin-bottom: 50px;

    @media screen and (max-width: 650px) {
      display: block;
    }

    .left {
      flex: 1;
    }
    .right {
      flex: 3;
      padding-left: 30px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 650px) {
        padding-left: 0;
      }

        .author-name {
          font-size: 24px;
          flex: 2;
        }
        .author-position {
          color: #a5a5a5;
          font-size: 17px;
          flex: 1;
        }
        .author-about {
          margin-top: 10px;
          line-height: 19px;
          font-size: 17px;
          flex: 7;
        }
        .author-links {
          flex: 2;
          margin-bottom: 5px;

          span {}
          a {
            width: 20px;
            margin-left: 15px;
            display: inline-block;

            svg {
              vertical-align: middle;
              width: 21px;
              height: 20px;

              &.in {
                margin-top: -3px;
              }
            }
          }
        }
    }
  }

  h2.section-title {
    font-size: 24px;
    margin: 20px 0;
  }

  .spacer {
    height: 150px;
  }
}