// ==========================
// Config
// ==========================

// --------------------------
// Breakpoints
// --------------------------

$breakpoints: (
    xxxs: 380px,    // 'mobile'
    xxs: 600px,     // 'small'
    xs: 640px,      // 'mobile-horizontal'
    sm: 700px,      // 'menu'
    md: 768px,      // 'tablet'
    lg: 900px,      // 'medium'
    xl: 1024px,     // 'tablet-horizontal'
    xxl: 1060px,    // 'sidebar'
    xxxl: 1240px,   // 'large'
);

// --------------------------
// Color palette map
// --------------------------
// 1. set basic palette
// 2. set common semantic variables
// 3. use semantic one only when it makes sense ($border-color for border
//    property)
// 4. use directly from palette otherwise (palette(gray, light); for section
//    background
//
// http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/

// Místo tohoto bych raději použíl obyčejné proměné.

$palettes: (
    primary: (
        base:  #3DA755,
        dark: #222,
    ),
    orange: (
        //base: #f79c3d,
        base: #3DA755,
    ),
    white: (
        base: #fff,
    ),
    gray: (
        x-light: #f6f6f6,
        light: #ddd,
        base: #999,
        dark: #666,
        border: #ccc,
    ),
    black: (
        base:  #222,
        transparent: rgba(0, 0, 0, 0.75),
    )
);

@function palette($palette, $tone: 'base') {
    @return map-get(map-get($palettes, $palette), $tone);
}

// --------------------------
// Color variables
// --------------------------
$main-green:        palette(primary);
$green:             $main-green;
$dark-green:        palette(primary, dark);
$orange:            palette(orange);
$white:             palette(white);
$dark-gray:         palette(gray, dark);
$border-gray:       palette(gray, border);
$medium-gray:       palette(gray);
$gray:              $medium-gray;
$light-gray:        palette(gray, light);
$background-gray:   palette(gray, x-light);
$black:             palette(black);

// --------------------------
// Typography colors
// --------------------------
$muted-type: palette(gray);
$type-color--muted: $muted-type;

// --------------------------
// Helper colors
// --------------------------
// $border-color: palette(gray);
$transparent: rgba(255,255,255, 0.0001); // because of safari's gradient bug

// $error-color: #dd1515;
// $warning-color: #f7922b;
// $success-color: palette(green);

// --------------------------
// Link colors
// --------------------------
$link-color:            palette(primary);
$link-color--hover:     palette(primary);
$link-color--active:    palette(primary, dark);
$link-color--visited:   palette(gray, dark);

// --------------------------
// Spacing
// --------------------------
// $spacing: 20px;
// the most used spacing is 30, 40 and 50px. In this case it'll be easier to write it directly without variables

$max-width: 1218px;

// --------------------------
// Helpers
// --------------------------
// $border-radius: 5px;
