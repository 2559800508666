.contactus-page {
    margin-bottom: 3em;

    .contactus-form .submit-button {
        max-width: 100%;
        display: inline;
        width: auto;
    }

    .agree-checkbox {
        margin-bottom: 1em;

        input {
            float: left;
            width: 1.5em;
            height: 1.5em;
        }

        label {
            width: 82%;
            float: left;
            margin-left: 1em;
        }

        .message--error {
            clear: both;
        }
    }
}

.contactus-sucess {
    color: $green;
    text-align: center;
    margin-bottom: 2em;
}