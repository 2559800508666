.header-search-form {

    .form-wrapper {
        position: relative;
    }
    .search-field {
        display: block;
        border: 0;
        //height: 60px;
        //padding: 20px 20px;
        height: 50px;
        padding: 15px 20px;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: $black;
        border-radius: 0;

        &:focus {
            outline: none;
        }
    }
    .search-button {
        position: absolute;
        top: 0;
        right: 0;
        //width: 60px;
        //height: 60px;
        width: 50px;
        height: 50px;
        border: none;
        background: #FFF;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, #fff 30%);
        text-align: center;
        padding: 0 0;

        @media screen and (max-width: 600px) {
            height: 50px;
            width: 50px;
        }

        .ico {
            display: block;
            fill: $main-green;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 17px;
            height: 17px;
            margin-left: -9px;
            margin-top: -9px;

            @media screen and (max-width: 600px) {
                margin-top: -5px;
            }
        }

        &:hover,
        &:focus,
        &:active {
            .ico {
                fill: $dark-green;
            }
            outline: none;
        }
    }
}
