* {
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

body {
    background: #fff;
    color: #000;
    font-size: 16px;
    line-height: 1.5;
    overflow-x: hidden;

    font-kerning: normal;
    font-feature-settings: "kern", "liga", "calt";
}

/*::selection {
    background: lighten(#000, 10%);
}

::-moz-selection {
    background: lighten(#000, 10%);
}*/
