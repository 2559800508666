.powered-by {
    border-top: 5px solid palette(gray, light);
    padding-top: 50px;
    margin: 50px 15px 0;
    text-align: center;

    @media screen and (max-width: 1000px) {
        margin-top: 40px;
        padding-top: 30px;
    }
}

.powered-by__label {
    color: palette(gray);
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 21px;
}
