.button {
    @include transition();
    @include transition-property(background, border, color);
    background: $main-green;
    border: 1px solid $dark-green;
    color: $white;
    letter-spacing: 0;
    padding: 7px 15px 8px;
    font-weight: bold;
    display: inline-block;

    @include screen(false, lg) {
        padding: 8px 15px 9px;
    }

    &:visited {
        color: $white;
    }

    &:hover,
    &:focus {
        @include transition();
        @include transition-property(background, border);
        background: $dark-green;
        color: $white;
        text-decoration: none;
    }

    &:active {
        @include transition();
        @include transition-property(background, border);
        background: $black;
        border-color: $black;
        color: $white;
        text-decoration: none;
    }
}

.button--outline {
    border: 2px solid $main-green;
    background: none;
    color: $main-green;

    &:visited {
        color: $main-green;
    }

    &:hover,
    &:focus {
        @include transition();
        @include transition-property(background, border, color);
        background: $main-green;
        color: $white;
        text-decoration: none;
    }

    &:active {
        @include transition();
        @include transition-property(background, border);
        background: $dark-green;
        border-color: $dark-green;
        color: $white;
        text-decoration: none;
    }
}
