.choices {
    position: relative;
}

.decorated-multiselect {
    padding-bottom: 10px;
    position: relative;
}

.multiselect-loading {
    @extends .loading-gif;
    
    position: absolute;
    right: 0.5em;
    bottom: 13px;
}

.multiselect-input {
    width: auto !important;
}

.multiselect-list {
    overflow: hidden;
}

.multiselect-dropdown {
    background: $white;
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;

    @media only screen and (min-width: 768px) {
        position: absolute;
        width: 100%;
        z-index: 10;
        display: none;
    }

    .multiselect-tag {
        cursor: pointer;
        padding: 10px;
        border-bottom: 1px solid $light-gray;
    }
}

.multiselect-dropdown.is-active {
    @media only screen and (min-width: 768px) {
        display: block;
    }
}


.multiselect-remove-tag {
    cursor: pointer;
}
