.about-lecturagroup-section {
    margin-top: 48px;

    @include screen(false, lg) {
        margin-top: 38px;
    }

    @media screen and (max-width: 600px) {
        margin-top: 11px;
    }

    .wrapper {
        @media (max-width: 480px) {
            padding: 0 0;
        }
    }

    .lectura-specs {
        margin: 25px 0 35px;
        text-align: center;

        @include screen(false, lg) {
            margin: 14px 0 26px;
        }

        @media screen and (max-width: 600px) {
            margin: 4px 0 22px;
        }

        .logo {
            display: block;
            margin: 0 auto 10px;

            @include screen(false, lg) {
                margin-bottom: 12px;
            }
        }

        p {
            margin-bottom: 18px;

            @include screen(false, lg) {
                line-height: 1.3;
                margin-bottom: 21px;
            }
        }

        .photo-cols {
            margin: 35px auto 0;
            width: 100%;
            max-width: 1100px;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            height: 135px;

            @include screen(false, lg) {
                margin-top: 31px;
            }

            @media screen and (max-width: 500px) {
                height: 57px;
            }


            .photo-col {
                width: 149px;
                float: left;
                padding: 0 2px;
                height: 100%;

                @media screen and (max-width: 600px) {
                    width: 25%;
                }
            }

            .photo {
                box-shadow: inset 0 0 1px solid red;

                img {
                    display: block;
                }
            }
        }
    }

    .lectura-more {
        margin: 10px 0;

        @include screen(false, lg) {
            margin: 0;
        }

        .lectura-more-header {
            text-align: center;
            width: 100%;
            max-width: 500px;
            margin: 0 auto 35px;

            @media screen and (max-width: 600px) {
                margin-bottom: 27px;
            }

            h2 {
                margin-bottom: 10px;

                @include screen(false, lg) {
                    margin-bottom: 15px;
                }
            }

            p {
                font-size: 16px;
                line-height: 1.3;

                @media screen and (max-width: 600px) {
                    display: none;
                }
            }
        }

        .lectura-more-cols {
            width: 100%;
            max-width: 600px;
            margin: 0 auto;
            @include clearfix();

            @media screen and (max-width: 600px) {
                &:before {
                    display: none;
                }
            }

            .lectura-more-col {
                padding: 0 10px;
                float: left;
                width: 50%;

                @media screen and (max-width: 600px) {
                    float: none;
                    width: auto;
                }
            }
        }

        .lectura-more-box {
            text-align: center;

            @media screen and (max-width: 600px) {
                margin-top: 27px;
            }

            .logo {
                display: block;
                margin: 0 auto 13px;
            }

            .lectura-valuation {
                //
            }

            .lectura-shop {
                margin-top: -15px;

                @media screen and (max-width: 600px) {
                    margin-top: 0;
                }
            }

            p {
                //
            }
        }
    }
}
