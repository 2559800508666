.custom-select-box {
    padding: 0;
    margin: 0;
    border: 2px solid $white;
    border-radius: 0;
    min-width: 175px;
    overflow: hidden;
    background: url('../img/ico/arrow-down-white.svg') no-repeat 90% 50%;
    background-color: palette(primary);
    padding: 7px 30px 8px 12px;
    transition: background 0.3s ease;
    position: relative;

    .no-flexbox & {
        display: inline-block;
    }

    &:hover,
    &:focus {
        background: url('../img/ico/arrow-down-green.svg') no-repeat 90% 50%;
        background-color: $white;
        cursor: pointer;

        > select {
            color: palette(gray, dark);
        }

        &:before {
            background: $white;
        }
    }

    &:not(hover),
    &:not(focus) {
        color: $white;
    }

    // hide IE arrows
    &:before {
        display: block;
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        height: 36px;
        width: 10px;
        background: palette(primary);
        z-index: 5;
        transition: background 0.3s ease;
    }
}

.custom-select-box select {
    color: $white;
    padding: 0;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
        cursor: pointer;
    }
}

.custom-select-box select:focus {
    outline: none;
}
