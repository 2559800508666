.highlight-section {
    @include clearfix;
    border-top: 5px solid $main-green;
    border-bottom: 1px solid palette(gray, light);
    padding: 50px 0;
    background: palette(gray, x-light);
    overflow: hidden;
    position: relative;

    @include screen(false, lg) {
        padding: 40px 0;
    }

    @include screen(false, xxs) {
        padding: 30px 0;
    }
}

.highlight-section__thumbnail-wrapper {
    width: 3000px;
    height: 280px;

    @include screen(false, xxs) {
        height: 240px;
    }
}

.highlight-section__thumbnail {
    @include column;
    width: 438px;
    position: relative;

    @include screen(false, xxs) {
        max-width: 360px;
        max-width: calc(100vw - 15px);
    }

    .thumbnail__title {
        @include heading--like-h3;

        @include screen(false, xxs) {
            @include heading--like-h4;
        }
    }
}

.highlight-section__heading {
    margin-bottom: 25px;
}

.highlight-section__float-link {
    @include float-link;
}
