// ==========================
// Accordion
// ==========================

.accordion {
    border: 1px solid palette(gray);
    border-radius: 4px;
    padding: 25px;
    margin-bottom: 17px;

    &.active {
        border-color: palette(gray, dark);
    }
}

.accordion__tab {
    @include heading--base;
    margin: 0;
    position: relative;
    cursor: pointer;
    outline: 0;
    font-weight: 700;
    color: palette(primary);

    @media print {
        display: none;
    }

    .js-accordion.active & {
        margin-bottom: 0;
        color: palette(black);

        @media print {
            display: block;
        }
    }
}

.accordion__icon {
    transition: opacity 0.3s ease;
    opacity: 1;
    width: 15px;
    height: 9px;
    margin-left: 3px;

    .accordion.active & {
        opacity: 0;
    }
}

.accordion__close-icon {
    width: 15px;
    height: 9px;
    margin-left: 5px;
}



.accordion__content {
    overflow: hidden;

    .js & {
        transition: max-height 0.3s ease;
        max-height: 36px;

    }
    .js-accordion.active & {
        max-height: 80em;
        overflow: visible;

        p {
            width: 100%;
            white-space: normal;
            overflow: visible;
            text-overflow: none;
        }
    }

    p:first-child {
        margin-top: 10px;
        width: 85%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.accordion__close {
    font-weight: 700;
}
