//# Mixins

@mixin placeholder-color($default-color, $focus-color) {
    &::-webkit-input-placeholder {
        color: $default-color;
        transition: color 250ms;
    }

    &:-moz-placeholder {
        color: $default-color;
        transition: color 250ms;
    }

    &::-moz-placeholder {
        color: $default-color;
        transition: color 250ms;
    }

    &:-ms-input-placeholder {
        color: $default-color;
        transition: color 250ms;
    }

    &:focus::-webkit-input-placeholder {
        color: $focus-color;
    }

    &:focus:-moz-placeholder {
        color: $focus-color;
    }

    &:focus::-moz-placeholder {
        color: $focus-color;
    }

    &:focus:-ms-input-placeholder {
        color: $focus-color;
    }
}

@mixin clearfix() {
    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}


//## Media Queries

@mixin retina($ratio: 1.5) {
    $resolution: $ratio * 96dpi;

    @media (min-device-pixel-ratio: $ratio), (min-resolution: $resolution) {
        @content;
    }
}

@function screen($key: md, $mobileFirst: true) {
    $breakpointValue: map-get($breakpoints, $key);

    @return if($mobileFirst, $breakpointValue + 1, $breakpointValue);
}
@mixin screen($minValue: null, $maxValue: null) {
    $isSegment: $minValue and $maxValue;
    $isIncrease: $minValue;
    $isDecrease: $maxValue;

    @if $isSegment {
        @media (min-width: screen($minValue)) and (max-width: screen($maxValue, false)) {
            @content;
        }
    } @else if $isIncrease {
        @media (min-width: screen($minValue)) {
            @content;
        }
    } @else if $isDecrease {
        @media (max-width: screen($maxValue, false)) {
            @content;
        }
    } @else {
        @content;
    }
}


// --------------------------
// media object mixins
// --------------------------
// http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/
@mixin media-object {
    overflow: hidden;
    _overflow: visible;
    zoom: 1;
}

@mixin media-object__body {
    overflow: hidden;
    _overflow: visible;
    zoom: 1;
}

@mixin media-object__side {
    float: left;
    margin-right: 30px;

    img {
        display: block;
    }
}

@mixin media-object__right-side {
    float: right;
    margin-left: 30px;

    img {
        display: block;
    }
}



// --------------------------
// flag object mixins
// --------------------------
@mixin flag {
    display: table;
    width: 100%;
}

@mixin flag__body {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

@mixin flag__side {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;

    > img {
        display: block;
        max-width: none;
    }
}



// --------------------------
// Transition default
// --------------------------
// define transition with @include transition;
//
// if you need override setting, just specify it as an argument
// e.g. @include transition(opacity 0.5s ease-out);
//
// if you want to choose only specific property to achieve better
// performance while keeping default timing, use transition-property
// e.g. @include transition-property(opacity, background);
// and choose only property you need to transition

@mixin transition($transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1)) {
    transition: $transition;
}

@mixin transition-property($property...) {
    transition-property: $property;
}

%link-underline--hover {
    color: $link-color;
    background-image: none;
}

@mixin link-underline {
    @include transition();
    @include transition-property(color);
    text-decoration: none;
    background-position: 0 1.05em;
    background-size: 2px 2px;
    background-image: linear-gradient($transparent 50%, $white 50%);
    background-repeat: repeat-x;

    &:hover,
    &:focus {
        background-image: none;
    }
}

@mixin link-underline--invert {
    @include link-underline;
    background-image: none;

    &:hover,
    &:focus {
        background-image: linear-gradient($transparent 50%, $white 50%);
    }
}

// --------------------------
// Common styles
// --------------------------
@mixin tag {
    font-size: 13px;
    line-height: 1.6;
    display: inline-block;
    color: $white;
    background: palette(orange);
    text-transform: uppercase;
    padding: 6px;
    font-weight: bold;
}

@mixin float-link {
    color: $black;
    font-weight: bold;
    float: right;
    margin-top: 16px;

    @include screen(false, lg) {
        margin-top: 8px;
    }

    @include screen(false, xxs) {
        display: none;
    }

    &:visited {
        color: $black;
    }
}
