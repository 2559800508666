.news-column {
    @media screen and (min-width: 1024px) {
        width: 20%;
    }
    @include screen(false, xxs) {
        width: 100%;
    }
}

.news-column__more-link {
    font-size: 15px;
}

.thumbnail__title {
    font-weight: normal;
}

.clearfix {
    clear: both;
}

.lang-dropdown, .lang-dropdown__list {
    z-index: 1100;
}

.page-top-navigation .nav-links .search-link .header-search-form {
    z-index: 1101;
}

.page-top-navigation .nav-links .lang-dropdown .arrows {
    cursor: pointer;
}

.flag-icon-en {
    @extend .flag-icon-gb;
}

textarea {
    resize: none;
}

.highlight-section__thumbnail .thumbnail__title {
    font-size: 26px;
}

.seo-text, .seo-text div {
    text-align: left !important;
}

@include screen(false, lg) {
    .photo-thumbnail__tag {
        max-width: 88%;
    }
}

@import "lectura-components/advert-section";
@import "lectura-components/forms";
@import "lectura-components/no-image";
@import "lectura-components/thumbnail";
@import "lectura-components/articles";
@import "lectura-components/article";
@import "lectura-components/article-image";
@import "lectura-components/sponsors";
@import "lectura-components/tag";
@import "lectura-components/custom-selectbox";
@import "lectura-components/search";
@import "lectura-components/about";
@import "lectura-components/contactus";
@import "lectura-components/multiselect";
@import "lectura-components/autocomplete";
@import "lectura-components/copy";
@import "lectura-components/lory";
@import "lectura-components/photo-slideshow";
@import "lectura-components/header";
@import "lectura-components/channels";
@import "lectura-components/reports";
@import "lectura-components/classic-report-detail";
@import "~plyr/src/scss/plyr";
@import "~baguettebox.js/src/baguetteBox";
@import "lectura-components/plyr";
@import "lectura-components/news_column";
@import "lectura-components/footer";
@import "lectura-components/article_external_content";
@import "lectura-components/video-section";
@import "lectura-components/newsletter";
@import "lectura-components/common-elements";
@import "lectura-components/report";
@import "lectura-components/cookies";
@import "lectura-components/404";
@import "lectura-components/banners";
@import "lectura-components/timeline";
