.serp-section {
    margin-top: 49px;
    margin-bottom: 20px;
    border-bottom: 5px solid #ddd;

    @media screen and (max-width: 1000px) {
        margin-top: 40px;
        border-bottom: none;
        margin-bottom: 16px;
    }

    @media screen and (max-width: 600px) {
        margin-top: 30px;
        margin-bottom: 14px;
    }

    + .serp-section {
        margin-top: 23px;

        @media screen and (max-width: 1000px) {
            margin-top: 13px;
        }
    }

    &.var-without-border {
        border-bottom: none;
    }

    h2 {
        margin-bottom: 14px;

        @media screen and (max-width: 1000px) {
            font-size: 21px;
        }
    }

    .load-more-button-wrapper {
        text-align: center;
        margin: 0px 0 50px;

        @media screen and (max-width: 1000px) {
            margin-bottom: 24px;
        }

        @media screen and (max-width: 600px) {
            margin-top: 5px;
            margin-bottom: 14px;
        }
    }

    .report__wrapper {
        margin-bottom: 50px;
    }

    .serp-section__header {
        @media screen and (max-width: 1000px) {
            color: $main-green;
            border-top: 5px solid #499b25;
            padding-top: 14px;
            cursor: pointer;

            h2 {
                display: inline-block;

                &:after {
                    position: relative;
                    display: inline-block;
                    width: 10px;
                    height: 17px;
                    margin: 0 0 -2px 16px;
                    content: '';
                    background: url('../img/ico/arrow-down-green.svg') 50% 50% no-repeat;
                }
            }

            &.is-active {
                h2 {
                    &:after {
                        background: url('../img/ico/arrow-up-green.svg') 50% 50% no-repeat;
                    }
                }

                + .serp-section__body {
                    display: block;
                }
            }
        }

        @media screen and (max-width: 600px) {
            padding-top: 9px;
        }
    }

    .serp-section__body {
        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
}
