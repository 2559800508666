@media (min-width: 1050px) {
    .page-top-header .links__wrapper {
        width: 680px !important;
    }
}

.custom-select-box {
    background-image: none;
    padding: 7px 0 0;
    text-align: left;
    width: 200px;
    z-index: 1003;

    @media (min-width: 1050px) {
        position: absolute;
        left: 0;
        top: 0;

        &.zero {
            width: 190px;
            left: 155px;
        }

        &.first {
            width: 150px;
            min-width: 150px;
        }

        &.second {
            //left: 210px;
            //width: 250px;

            left: 350px;
            width: 200px;
        }

        &.third {  /*i dont like this change*/
            //left: 470px;

            left: 555px;
            width: 115px;
            min-width: 100px;
        }
    }

    .screen-reader-overlay {
        display: inline-block;
        width: 100%;

        .screen-reader-text {
            display: inline-block;
            padding: 0 30px 0 12px;
            background: url("../img/ico/arrow-down-white.svg") no-repeat 98% 50%;
            transition: background 0.3s ease;
            position: relative;
            width: 100%;
            height: auto;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 300;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        list-style-position: inside;
        overflow-y: auto;
        max-height: 500px;
        display: none;

        @include screen(false, xxs) {
            max-height: 200px;
        }

        li {
            padding: 5px 12px;

            a {
                margin: 0 !important;
                width: 100%;
            }

            &:before {
                display: none;
            }
        }
    }

    &.is-open {
        background-image: none;
        background: palette(white);
        border-color: palette(gray, border);

        .screen-reader-overlay {
            border-bottom: 2px solid palette(gray, border);

            .screen-reader-text {
                color: palette(gray, dark);
                background: url("../img/ico/arrow-down-green.svg") no-repeat 98% 50%;
            }
        }

        ul {
            display: block;

            li a {
                color: palette(gray, dark);
            }
        }
    }

    &.is-not-open {
        background: palette(primary);
        color: palette(white);
    }

    @media (min-width: 1200px) {
        &:hover,
        &:focus,
        &.is-open {
            background-image: none;
            background: palette(white);
            border-color: palette(gray, border);

            .screen-reader-overlay {
                border-bottom: 2px solid palette(gray, border);

                .screen-reader-text {
                    color: palette(gray, dark);
                    background: url("../img/ico/arrow-down-green.svg") no-repeat 98% 50%;
                }
            }

            ul {
                display: block;

                li a {
                    color: palette(gray, dark);
                }
            }
        }
    }

    &:before {
        display: none;
    }

    &.is-open {
        .company-filter-wrapper {
            .company-filter-input {
                display: block;
            }
        }
    }

    .company-filter-wrapper {
        position: relative;

        .company-filter-input {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1002;
            background: palette(white);
            display: none;

            input {
                border: 0;
                background-image: none !important;
            }
        }
    }
}

.page-top-header .links__wrapper {
    @media (min-width: 1050px) {
        position: relative;
        width: 630px;
        height: 42px;
    }
}
