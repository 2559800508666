input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type="text"],
input[type="search"],
input[type="file"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
textarea {
    @include placeholder-color(#6c778b, #ecebe8);
    display: block;
    background: $white;
    width: 100%;
    border: 1px solid $light-gray;
    color: $medium-gray;
    letter-spacing: 0;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 19px;
    background-clip: padding-box;
    padding: 9px 15px 10px;
    box-sizing: border-box;
    border-radius: 0;
    -webkit-appearance: none;

    &:last-child {
        margin-bottom: 0;
    }

    &.error {
        border-color: $orange;
    }

    &:focus,
    &.has-focus {
        outline: none;
        border-color: $medium-gray;
    }

    &:active {
        outline: none;
    }
}

input[type="submit"] {
    padding: 7px 15px 8px;
    height: 46px;
    border-radius: 0;
    -webkit-appearance: none;
}

label,
.like-label {
    //
}

.decorated-checkbox {
    display: block;
    display: block;
    position: relative;
    padding-left: 27px;
    min-height: 20px;
    text-align: left;

    &.var-inline {
        display: inline-block;
    }

    input {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        outline: none;
        -moz-appearance: none;;
        cursor: pointer;
        z-index: 1;
        opacity: 0;
        border-radius: 0;
        background-color: #fff;
        border: 1px solid $light-gray;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
        &:disabled {
            pointer-events: none;
            opacity: 0.4;
            ~ * {
                pointer-events: none;
                opacity: 0.4;
            }
        }
        &:checked {
            + .decoration {
                //
                &:before {
                    display: block;
                    content: "";
                    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjExcHgiIGhlaWdodD0iOHB4IiB2aWV3Qm94PSIwIDAgMTEgOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KPGcgaWQ9IlByZXNz4oCTU3Vic2NyaWJlIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KPGcgaWQ9IlByZXNz4oCTU3Vic2NyaWJlUlNTIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTQ3LjAwMDAwMCwgLTI5Mi4wMDAwMDApIiBmaWxsPSIjNzZCQzRDIj4KPGcgaWQ9IkNvbnRlbnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUxMi4wMDAwMDAsIDExMC4wMDAwMDApIj4KPGcgaWQ9IlBlcnNvbmFsaXplIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgMTE0LjAwMDAwMCkiPgo8ZyBpZD0iaW5wdXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMwLjAwMDAwMCwgMzAuMDAwMDAwKSI+CjxnIGlkPSJjaGVja2JveCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDMxLjAwMDAwMCkiPgo8cGF0aCBkPSJNMTUuMjY5NTQ5NSw4LjI5Njg2NzAxIEMxNS40MTAxNzQ2LDguMTQ0NTIzMDggMTUuNDEwMTc0Niw3LjkxMDE0NzggMTUuMjY5NTQ5NSw3Ljc2OTUyMjYzIEwxNC43MzA0ODYzLDcuMjMwNDU5NDkgQzE0LjU4OTg2MTEsNy4wODk4MzQzMiAxNC4zNTU0ODU5LDcuMDg5ODM0MzIgMTQuMjAzMTQxOSw3LjIzMDQ1OTQ5IEw5LjAwMDAxMDczLDEyLjQzMzU5MDcgTDYuNDIxODgyNjYsOS44NTU0NjI2MiBDNi4yNjk1Mzg3Miw5LjcxNDgzNzQ1IDYuMDM1MTYzNDQsOS43MTQ4Mzc0NSA1Ljg5NDUzODI4LDkuODU1NDYyNjIgTDUuMzU1NDc1MTMsMTAuMzk0NTI1OCBDNS4yMTQ4NDk5NywxMC41MzUxNTA5IDUuMjE0ODQ5OTcsMTAuNzY5NTI2MiA1LjM1NTQ3NTEzLDEwLjkyMTg3MDEgTDguNzMwNDc5MTYsMTQuMjk2ODc0MiBDOC44ODI4MjMwOSwxNC40Mzc0OTkzIDkuMTE3MTk4MzcsMTQuNDM3NDk5MyA5LjI2OTU0MjMsMTQuMjk2ODc0MiBMMTUuMjY5NTQ5NSw4LjI5Njg2NzAxIFoiIGlkPSLvibQiPjwvcGF0aD4KPC9nPgo8L2c+CjwvZz4KPC9nPgo8L2c+CjwvZz4KPC9zdmc+Cg==");
                    position: absolute;
                    top: 6px;
                    left: 4px;
                    width: 11px;
                    height: 8px;
                }
            }
            ~ .label {
                //font-weight: bold;
            }
        }
        &:hover,
        &.has-hover {
            + .decoration {
                border-color: $black;
            }
        }
    }
    .decoration {
        display: block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        background-clip: padding-box;
        outline: none;
        background-color: #fff;
        border: 1px solid $light-gray;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5);
        border-radius: 0px;
    }
    .label {
        display: block;
        margin: 0;
    }
}

.form-row {
    display: block;
    margin: 19px 0;

    >label,
    >.like-label {
        display: block;
        margin-bottom: 3px;
    }

    >input,
    >textarea {
        display: block;
    }
}
