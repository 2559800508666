.slideshow {
    position: relative;

    ul {
        margin: 0;
    }
}

.slideshow__slide {
    padding-left: 0;

    &:before {
        content: none;
    }

    li {
        img,
        picture {
            display: block;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}

.slideshow__tag {
    @include tag;
    position: absolute;
    top: 20px;
    left: 20px;
}

.slideshow__link {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: $white;
    @include link-underline;
    font-weight: bold;
    text-shadow: 0 0 5px palette(black);

    &:visited {
        color: $white;
    }
}
