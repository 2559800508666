.report__wrapper {
    @include column;
    width: 20%;

    @include screen(false, lg) {
        width: 33.3%;

        &:nth-child(n+4) {
            display: none;
        }
    }

    @include screen(false, xxs) {
        width: 50%;

        &:nth-child(n+3) {
            display: none;
        }
    }
}

a.report {
    &:hover,
    &:focus {
        .report__title {
            color: $green;
        }
    }
}

.report__image {
    border: 5px solid $white;
    outline: 1px solid palette(gray, light);
}

.report__title {
    @include heading--like-h5;
    font-weight: bold;
    color: $black;
}
