.copy-message {
    display: none;
}

.copy-message-success {
    color: $green; 
}

.copy-message-fail {
    color: $orange;
}
