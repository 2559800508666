.expo-section {
    padding: 50px 0 0;

    @include screen(false, lg) {
        padding-top: 40px;
    }

    @include screen(false, xxs) {
        padding-top: 30px;

        .wrapper > .row {
            margin: 0 -17.5px
        }
    }
}
