.page-top-navigation {
    background-color: $dark-green;
    color: #FFF;
    padding: 0 20px;

    @media screen and (max-width: 600px) {
        padding: 0 15px;
    }

    .common-section-inner {
        position: relative;
        height: 60px;

        @media screen and (max-width: 600px) {
            height: 50px;
        }
    }

    a {
        color: #FFF;
    }

    .logo-link {
        display: block;
        width: 111px;
        position: absolute;
        height: 60px;
        padding-top: 17px;
        padding-bottom: 17px;

        @media screen and (max-width: 600px) {
            height: 50px;
            width: 92px;
            padding-top: 15px;
            padding-bottom: 10px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            min-height: 29px;
        }

    }

    .signin-link {
        background: #3c6721;
        height: 60px;
        display: block;
        position: absolute;
        top: 0;
        left: 135px;
        padding: 18px 20px 18px;
        text-decoration: none;

        @media screen and (max-width: 600px) {
            height: 50px;
            left: auto;
            right: 130px;
            padding: 13px 15px;
        }

        .ico {
            position: relative;
            bottom: -1px;
            fill: #fff;
            width: 12px;
            height: 15px;
            margin-right: 6px;

            @media screen and (max-width: 600px) {
                margin-right: 0;
            }
        }
        .innerText {
            @media screen and (max-width: 600px) {
                display: none;
            }
        }

        &:hover,
        &:focus {
            .innerText {
                text-decoration: underline;
            }
        }
    }

    .nav-links {
        margin-left: 253px;
        @include clearfix();
        font-size: 16px;
        line-height: 1.31;
        float: right;
        position: relative;

        @media screen and (max-width: 1200px) {
            position: static;
            margin-right: -20px;
        }
        @media screen and (max-width: 600px) {
            margin-right: 0;
        }

        .mobile-nav-links {
            @include clearfix();
            float: left;

            @media screen and (max-width: 1200px) {
                display: none;
                position: absolute;
                top: 100%;
                left: -20px;
                right: -20px;
                padding: 24px 30px;
                background: #fff;
                z-index: 10;
            }

            .nav-link {
                @media screen and (max-width: 1200px) {
                    background: #FFF !important;
                    color: $main-green;
                    font-size: 21px;
                    font-weight: bold;
                    display: block;
                    float: none;
                    border: none;
                    padding: 7px 0 8px;
                    height: auto;
                }
            }

            &.is-open {
                display: block;
            }
        }

        .close-mobile-nav-link {
            display: none;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 30px;
            right: 30px;

            .ico {
                fill: #999999;
                transition: fill 200ms;
                width: 100%;
                height: 100%;
            }

            &:hover,
            &:focus  {
                .ico {
                    fill: $black;
                }
            }
        }

        .lang-dropdown {
            float: left;
            width: 160px;
            position: relative;

            @media screen and (max-width: 1200px) {
                border: 1px solid $main-green;
                margin-top: 16px;
                width: 165px;
            }

            &.is-open {
                .lang-dropdown__list {
                    display: block;
                }

                .arrows {
                    .arrow-down {
                        display: block;
                    }

                    .arrow-down {
                        display: none;
                    }
                }
            }

            .arrows {
                position: absolute;
                top: 20px;
                right: 20px;

                @media screen and (max-width: 1200px) {
                    top: 8px;
                    right: 14px;
                }

                .arrow-up {
                    display: none;
                }

                .arrow-down {
                    //
                }

                .ico {
                    width: 14px;
                    height: 8px;
                    fill: #fff;

                    @media screen and (max-width: 1200px) {
                        fill: $main-green;
                    }
                }
            }

            a {
                width: 100%;
                display: block;
                background: #373737;
                height: 60px;
                padding: 20px 20px;
                border-left: 2px solid $main-green;
                text-decoration: none;

                @media screen and (max-width: 1200px) {
                    background: #FFF;
                    border-left: none;
                    color: $main-green;
                    padding: 9px 15px;
                    height: auto;
                }

                &:hover,
                &:focus {
                    >.innerText {
                        text-decoration: underline;
                    }
                }
            }

            .lang-dropdown__active-item {
                //
            }

            .lang-dropdown__list {
                display: none;
                position: absolute;
                top: 100%;
                left: 2px;
                right: 0;

                a {
                    border-left: none;
                }
            }
        }

        .search-link {
            width: 62px;
            height: 100%;
            float: left;
            display: block;
            float: left;
            background: #373737;
            height: 60px;
            padding: 20px 20px 20px 22px;
            border-left: 2px solid $main-green;
            cursor: pointer;

            @media screen and (max-width: 600px) {
                height: 50px;
                //position: absolute;
                //top: 0;
                //right: -15px;
                width: 50px;
                padding: 15px 14px;
                float: right;
                margin-right: -15px;
            }

            &.is-open {
                .header-search-form {
                    display: block;
                }

                .show-search-form {
                    display: none;
                }

                .hide-search-form {
                    display: block;
                }
            }


            .show-search-form {
                position: relative;
                
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .ico {
                    margin-top: 2px;
                }
            }

            .hide-search-form {
                display: none;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                .ico {
                    width: 17px;
                    height: 17px;
                    margin-top: 2px;
                }
            }


            .header-search-form {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                z-index: 10;

                @media screen and (max-width: 600px) {
                    top: 51px;
                    left: -15px;
                    right: -15px;
                }

                .search-field {
                    display: block;
                    border: 0;
                    height: 60px;
                    width: 100%;
                    padding: 10px;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 20px 20px;
                    color: $black;

                    &:focus {
                        outline: none;
                    }
                }
                .search-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 60px;
                    height: 60px;
                    border: none;
                    background: #FFF;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0.2) 0%, #fff 30%);
                    text-align: center;
                    padding: 0 0;

                    @media screen and (max-width: 600px) {
                        height: 50px;
                        width: 50px;
                    }

                    .ico {
                        display: block;
                        fill: $main-green;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 17px;
                        height: 17px;
                        margin-left: -9px;
                        margin-top: 0;

                        &.top {
                            margin-top: -9px;
                        }

                        @media screen and (max-width: 600px) {
                            margin-top: -5px;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        .ico {
                            fill: $dark-green;
                        }
                        outline: none;
                    }
                }
            }
        }

        .nav-link {
            display: block;
            float: left;
            background: #373737;
            height: 60px;
            padding: 20px 20px;
            border-left: 2px solid $main-green;
            text-decoration: none;

            &.active {
                background: $main-green;
                font-weight: bold;
            }

            &:hover,
            &:focus {
                .innerText {
                    text-decoration: underline;
                }
            }
        }

        .hamburger-link {
            display: none;
            padding: 20px 15px;

            @media screen and (max-width: 1200px) {
                display: block;
            }

            @media screen and (max-width: 600px) {
                height: 50px;
                height: 50px;
                position: absolute;
                top: 0;
                right: 35px;
                width: 95px;
                padding: 15px 14px;
            }

            .ico {
                width: 16px;
                height: 13px;
                margin-right: 4px;
                position: relative;
                bottom: -1px;
            }
        }

        .ico {
            fill: #fff;
        }
    }

    .flag-icon {
        width: 14px;
        margin-right: 2px;
    }
}
