.logos {
    text-align: center;
    
    .logos__image-wrapper {
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
}

.sponsors-headline {
    @include heading--like-h5;
    font-size: 80%;
    margin-bottom: 1em;
    color: $gray;
}