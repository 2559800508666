.js_slides {
    width: 12000px;

    .highlight-section__thumbnail {
        @media (max-width: 768px) {
            width: 438px;
        }
    }

}

.js_frame {
    width: 100%;

    position: relative;
    overflow: hidden;
}