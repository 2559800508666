.decorated-multiselect {
    min-height: 50px;
    background-color: #fff;
    border: 1px solid $light-gray;
    padding-top: 10px;
    padding-right: 10px;

    .multiselect-tag {
        background-color: $orange;
        color: #FFF;
        height: 30px;
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        padding: 7px 30px 7px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        position: relative;
        margin-left: 10px;
        margin-bottom: 10px;
        @include clearfix();
        float: left;

        .innerText {
            //
        }

        .multiselect-remove-tag {
            position: absolute;
            top: 8px;
            right: 9px;

            svg {
                width: 11px;
                height: 10px;
                fill: #fff;
            }
        }
    }

    .multiselect-input {
        background: #fff;
        width: 100%;
        border: none;
        height: 30px;
        font-size: 16px;
        line-height: 20px;
        padding: 5px 15px;
        cursor: pointer;
    }

    .multiselect-input-wrapper {
        overflow: hidden;
        height: 30px;
        min-width: 150px;
        margin-bottom: 10px;
    }
}
