.classic-report-detail {
    margin: 10px 0;

    .takealook {
        @include column;
        @extend .column-3-of-16;

        display: none;

        @media screen and (min-width: 1000px) {
            display: block;
        }

        a:not(.hidden) {
            text-align: center;
            display: block;
            position: relative;
            cursor: pointer;
            div {
                border: 1px solid palette(gray, light);
                padding: 5px;
            }
            img {
                border: 1px solid palette(gray, light);
            }
            strong {
                background: palette(primary);
                color: palette(white);
                padding: 10px;
                display: inline-block;
                position: relative;
                top: -20px;
                width: 80%;
            }
            &:hover {
                strong {
                    background: palette(primary, dark);
                }
            }
        }
    }

    .content {
        @media screen and (min-width: 1000px) {
            @include column;
            width: 80%;

            h1 {
                display: inline-block;
                width: 70%;
            }
        }
    }

    .partners {
        width: 100%;
        margin: 5px 0 20px;

        @media screen and (min-width: 1000px) {
            width: 30%;
            float: right;

            h4, h2 {
                margin: 6px 0 0 10px;
            }

            a {
                margin: 5px 0px 5px 10px;
            }
        }

        h4, h2 {
            font-size: 100%;
        }
        a {
            border: 1px solid palette(gray, light);
            padding: 5px 5px 5px 1px;
            float: left;
            width: 120px;
            height: 50px;
            display: block;
            white-space: nowrap;
            text-align: center;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
            & > .middlehelper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
            img {
                vertical-align: middle;
                max-width: 110px;
                max-height: 40px;
            }
        }
    }

    .mobile-thumnail {
        margin: 20px 0;

        @media screen and (min-width: 1000px) {
            display: none;
        }

        img {
            max-width: 100%;
        }
    }

    .boxes {
        margin: 20px 0;

        .box {
            background-color: palette(gray, x-light);
            padding: 10px;

            &.basic {
                input[type=checkbox] {
                    width: 25px;
                    height: 25px;
                }
                .note {
                    font-size: 80%;
                }
            }

            &.premium {
                margin: 10px 0 0;

                .button-wrapper {
                    margin: 10px 0;
                }
            }

            @media screen and (min-width: 1000px) {
                width: 49%;
                @include column;

                h2 {
                    font-size: 30px;
                    margin: 10px 0;
                }

                &.premium {
                    margin: 0;
                    float: right;

                    span {
                        display: inline-block;
                        width: 40%;
                        float: left;
                        strong {
                            display: block;
                            font-size: 150%;
                        }
                    }
                }
            }
        }
    }

    /*.testimonials {
        margin: 30px 0;
        .testimonial {
            padding: 10px;
            margin-bottom: 5px;
            border-bottom: 1px solid palette(gray, light);
            .image {
                text-align: center;
                span {
                    width: 50px;
                    height: 50px;
                    img {
                        max-width: 100%;
                        height: 50px;
                    }
                }
            }
            .content {
                p.name {
                    text-align: center;
                }
                .desc {
                    position: relative;
                    > img.leftquotes {
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    > img.rightquotes {
                        position: absolute;
                        right: 0;
                        bottom: -21px;
                    }
                    p {
                        padding: 35px 35px 10px 40px;
                        margin: 0;
                        font-style: italic;
                        font-weight: bolder;
                        color: palette(black);
                        position: relative;
                        text-align: center;
                    }
                }
            }
        }
    }*/

    .compare {
        h3 {
            font-size: 100%;
            font-weight: bold;
            margin-top: 20px;
            text-transform: uppercase;
            padding-bottom: 5px;
            border-bottom: 1px solid palette(gray, light);
        }

        ul {
            list-style-type: upper-alpha;

            li {
                text-transform: capitalize;
                padding: 0;
                margin-left: 20px;

                &:before {
                    display: none;
                }

                span {
                    float: right;
                }

                &.premium {
                    color: palette(primary, dark);
                    font-weight: bold;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}