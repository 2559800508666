.error404-section {
    margin: 18px 0 0;
    text-align: center;

    .error404-image {
        text-align: center;
    }

    .error404-meaasage {
        text-align: center;
        margin: 17px 0 0;

        h2 {
            margin-bottom: 22px;
        }
        p {
            //
        }
    }
}
