.search-results {
    &.without-border {
        margin: 0;
        border-bottom: 0;
    }

    .is-not-open {
        display: none;
    }

    .is-open {
        display: block;
    }
}