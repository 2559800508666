@media print {
    * {
        background: #fff !important;
        color: black !important; /* Black prints faster: sanbeiji.com/archives/953 */
        text-shadow: none !important;
    }

    a, a:visited {
        text-decoration: underline;
    }

    /*
    a[href]:after {
        content: " (" attr(href) ")";
        font-size: 8px;
    }
    */

    /*
    abbr[title]:after {
        content: " (" attr(title) ")";
        font-size: 8px;
    }
    */

    /* Do not show javascript and internal links */
    /*
    a[href^="javascript:"]:after, a[href^="#"]:after {
        content: "";
    }
    */

    /* Printing Tables: css-discuss.incutio.com/wiki/Printing_Tables */
    thead {
        display: table-header-group;
    }

    tr, img {
        page-break-inside: avoid;
    }

    @page {
        margin: 0.5cm;
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }

    h2, h3 {
        page-break-after: avoid;
    }
}
