.news-column {
    overflow: visible;

    .news-column__heading {
        .news-column__heading-icon.hidden {
            display: none;
        }
    }

    .news-column__content {
        @include screen(false, xxs) {
            overflow: visible;
            max-height: none;

            &.is-not-open {
                display: none;
            }
        }
    }
}
