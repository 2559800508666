.paginator {
    margin: 20px 0 0;

    @media screen and (max-width: 1000px) {
        margin-top: 13px;
    }

    .center {
        text-align: center;
    }

    .prev-link,
    .next-link {
        display: inline-block;
        margin: 0 30px;
        color: $green;

        .ico {
            width: 9px;
            height: 16px;
            fill: $green;
            position: relative;
            bottom: -3px;
        }

        .innerText {
            @media screen and (max-width: 400px) {
                display: none;
            }
        }
    }

    .prev-link {
        margin-left: 0;

        .ico {
            margin-right: 5px;
        }
    }

    .next-link {
        margin-right: 0;

        .ico {
            margin-left: 5px;
        }
    }

    .steps {
        display: inline-block;

        .step {
            display: inline-block;
            margin: 0 10px;

            @media screen and (max-width: 1000px) {
                margin: 0 5px;
            }

            &.active {
                font-weight: bold;
                pointer-events: none;
                text-decoration: none;
                color: $black;
            }
        }
    }
}
