.search-form-404 {
    @extend .header-search-form;
    border: 1px solid $border-gray;
    margin: 0 auto;
    width: 60%;

    input {
        margin: 0;
    }

    @include screen(false, md) {
        width: 100%;
    }
}
