.top-news-section {
    border-top: 5px solid $border-gray;
    padding-top: 40px;
    margin: 28px 0;

    >h3 {
        margin-bottom: 26px;
    }

    .thumbnail__wrapper {
        @media screen and (max-width: 1000px) {
            width: 33.3%;
        }

        @media screen and (max-width: 700px) {
            width: 50%;
        }

        @media screen and (max-width: 450px) {
            width: auto;
            float: none;
        }

        &:nth-child(1) {
            @media screen and (max-width: 1000px) {
                display: none;
            }

            @media screen and (max-width: 700px) {
                display: block;
            }
        }
    }

    .thumbnail__image {
        @media screen and (max-width: 450px) {
            width: 100%;
        }
    }
}
