.about-lectura-contact-section {
    margin: 43px 0 0;
    text-align: center;

    @include screen(false, lg) {
        margin: 32px 0 0;
    }

    h2 {
        margin: 0 0 8px;
    }

    .contacts {
        margin: 20px 0;
        font-size: 16px;
        line-height: 1.31;

        .email {
            font-size: 21px;
            font-weight: bold;
            line-height: 1.29;
            margin-bottom: 8px;
        }
        .phone {
            //
        }
    }

    .facebook {
        width: 100%;
        max-width: 340px;
        margin: 51px auto 50px;

        @include screen(false, lg) {
            margin-top: 40px;
        }
    }
}
