.common-header {
    margin-top: 50px;
    padding-top: 38px;
    border-top: 5px solid palette(gray, light);

    @include screen(false, lg) {
        margin-top: 40px;
        padding-top: 30px;
    }

    @include screen(false, xxs) {
        margin-top: 30px;
        padding-top: 20px;
    }
}

.common-header__heading {
    @include heading--like-h2;
    margin-bottom: 25px;
}

.common-header__float-link {
    @include float-link;
}

.common-header__icon {
    fill: $black;
    font-size: 16px;
    display: inline-block;
    height: 16px;
    width: 12px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
