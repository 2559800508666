.pagination {
    display: block;
    margin: 50px auto 0;
    text-align: center;
    padding-left: 0;

    @include screen(false, xxs) {
        display: none;
    }

    li {
        display: inline-block;
        padding-left: 0;

        &:before {
            content: none;
        }

        a {
            padding: 0 5px;
            margin: 0 5px;
            font-weight: bold;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:visited {
                color: palette(primary);
            }

            &.active {
                color: $black;
            }
        }

        a span {
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }

    .pagination__icon {
        @extend .common-header__icon;
        fill: palette(primary);
    }

    .pagination__input {
        text-align: center;
        color: $black;
        display: inline-block;
        width: 45px;
    }

    .pagination__page-amount {
        display: inline-block;
        margin-left: 10px;
    }
}

.pagination--mobile {
    display: none;

    @include screen(false, xxs) {
        display: block;
    }
}
