.top-header {
    margin-top: 50px;

    @include screen(false, lg) {
        margin-top: 40px;
    }

    @include screen(false, xxs) {
        margin-top: 30px;
    }

    .common-header__heading {
        display: inline-block;
    }
}

.top-header__logo {
    margin-left: 20px;

    @include screen(false, lg) {
        display: block;
        margin: -15px 0 15px;
    }
}
