.serp-advert-section {
    margin-top: 5px;

    @media screen and (max-width: 1000px) {
        margin-top: 30px;
        padding-top: 21px;
        padding-bottom: 16px;
        position: relative;
    }

    @media screen and (max-width: 6000px) {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .section-inner {
        @media screen and (max-width: 600px) {
            padding-top: 21px;
            padding-bottom: 16px;
            border-top: 5px solid #ddd;
        }
    }

    @media screen and (max-width: 1000px) {
        margin-top: 17px;
    }

    .section-label {
        text-align: right;
        color: $gray;
        font-size: 14px;
        margin-top: -5px;
        margin-bottom: 15px;
    }

    .advertising-link {
        margin-bottom: 3px;
    }
}
