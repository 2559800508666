.thumbnail__wrapper--text {
    min-height: 360px;
}

.thumbnail__title {
    font-size: 19px;
}

.thumbnail__image img {
    @include screen(false, xxxl) {
        width: auto;
        height: 100%;
    }
}

.thumbnail__wrapper--double {
    .thumbnail__perex {
        font-size: 50%;
        display: block;
        line-height: 1.5;
    }
}

/*.thumbnail__wrapper--double {
    &.thumbnail-top-article {
        .thumbnail, picture, img {
            @media (min-width: 1025px) {
                height: 457px;
            }
        }
    }
}

.thumbnail-top-article {
    margin-bottom: 20px;
}

.thumbnail__link {
    &:visited {
        color: $black;
    }

    .perex {
        padding: 10px 20px;

        p {
            margin: 0;
        }
    }
}*/

.news-section {
    .thumbnail__wrapper {
        .thumbnail__image {
            @include screen(false, md) {
                height: 233px;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            @include screen(false, xs) {
                height: auto;
            }

            @include screen(false, xxxs) {
                height: 233px;
            }
        }
    }
}

.news-column-section {
    .news-column {
        @include screen(false, md) {
            width: 100%;
        }
    }

    .thumbnail__image img {
        @include screen(false, xs) {
            width: 100%;
            height: auto;
        }
    }
}

.thumbnail__wrapper {
    @include screen(false, md) {
        width: 50%;
        float: left;
    }

    @include screen(false, xxxs) {
        width: 100%;
        float: none;
    }
}

.video {
    .thumbnail__image {
        height: 230px;

        @include screen(false, lg) {
            height: auto;
        }
    }
}

.video-thumbnail__wrapper {
    .thumbnail__image {
        height: 334px;
        overflow: hidden;

        @include screen(false, lg) {
            height: auto;
        }

        @include screen(false, md) {
            height: 274px;
        }

        @include screen(false, xs) {
            height: 308px;
        }

        @include screen(false, xxxs) {
            height: 168px;
        }
    }

    &:not(.bigger) {
        .thumbnail__image {
            height: 158px;
            overflow: hidden;

            @include screen(false, xs) {
                height: 308px;
            }

            @include screen(false, xxxs) {
                height: 168px;
            }
        }
    }
}

.highlight-section__thumbnail {
    @include screen(false, md) {
        width: auto;
    }
}

.highlight-section__thumbnail-wrapper {
    @include screen(false, xl) {
        height: auto;
    }
}

.thumbnail__wrapper--double {
    @include screen(false, md) {
        width: 100%;
        margin-bottom: 20px;

        .thumbnail .thumbnail__image {
            height: 490px;
        }
    }

    @include screen(false, xs) {
        .thumbnail .thumbnail__image {
            height: 369px;
        }
    }

    @include screen(false, xxxs) {
        .thumbnail .thumbnail__image {
            height: 233px;
        }
    }
}

.expo-box__wrapper {
    &.expo-box__wrapper--large {
        @include screen(false, xl) {
            .expo-box__thumbnail {
                display: block;

                &:last-child {
                    display: none;
                }
            }
        }

        @include screen(false, md) {
            .expo-box__thumbnail:nth-child(3) {
                display: none;
            }
        }

        @include screen(false, xs) {
            .expo-box__thumbnail {
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }
    }

    .expo-box__thumbnail {
        @include screen(false, xl) {
            width: 312px;
        }

        @include screen(false, md) {
            width: 303px;
        }

        @include screen(false, xs) {
            width: 100%;

            .thumbnail__image img {
                width: 100%;
            }

            .no-image {
                background-size: 100% auto;
                height: 354px;
            }
        }

        @include screen(false, xxxs) {
            width: 296px;

            .no-image {
                height: 185px;
            }
        }
    }
}

.thumbnail__channel {
    min-height: 0;

    a, a:visited, a:hover {
        color: $black;
    }

    .thumbnail {
        display: table;
        width: 100%;

        &:before {
            background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
        }

        .thumbnail__image {
            display: table-cell;
            vertical-align: middle;

            img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                margin: 0 auto;
                padding: 5px;
            }
        }
    }
}


.channel-section {
    @include screen(false, xxxs) {
        width: 100%;

        .js-slider {
            left: -8px;
        }
        .js_frame {
            height: 320px;
            left: 0;

            .js_slide {
                float: left;
            }
        }
    }

    .thumbnail__channel {
        width: 285px;

        @include screen(false, xxxs) {
            width: 320px;
        }
    }
}
