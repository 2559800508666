.article__tags {
    display: inline-block;
}

.outline-tag {
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 5px;
}

.specs-links a {
    padding: 15px 0 0;
}