.article__wrapper {
    background: palette(black, transparent);
    padding: 30px 0 50px;

    @include screen(false, xxs) {
        padding: 0;
    }
    .slide__nav {
        top: 380px;

        @media screen and (max-width: 1000px) {
            top: 340px;
        }

        @media screen and (max-width: 900px) {
            top: 320px;
        }

        @media screen and (max-width: 700px) {
            display: none;
        }
    }

    .channel-banner {
        padding: 20px 10px;
        height: 155px;
        max-width: 880px;
        margin: auto auto 0px;
        border-bottom: 3px solid #3DA755;

        @media (max-width: 600px) {
            padding: 10px 10px;
        }

        a {
            display: flex;
            height: 100%;
            align-items: center;

            @media (max-width: 600px) {
                flex-direction: column-reverse;
            }

            &:hover {
                text-decoration: none;
            }

            div.channel-logo {
                width: auto;
                height: 100%;
                overflow: hidden;
                position: relative;
                margin-right: 15px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 460px) {
                    display: none;
                }

                img {
                    max-width: 230px;
                    max-height: 99px;
                }
            }
            div.channel-title {
                flex: 5;

                p {
                    color: black;
                    margin-bottom: 0;
                    font-size: 20px;

                    &:first-child {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 22px;
                    }
                }
            }
            div.header-powered-by {
                position: relative;
                flex: 1;

                .header-powered-by__label {
                    color: black;
                    margin-bottom: 0;
                    font-size: 13px;
                    font-style: italic;
                    line-height: 21px;
                    padding-right: 10px;
                }
            }
        }
    }

}

.article {
    background: $white;

    @include screen(false, xxs) {
        margin: 0 -10px;
    }
}

.article--padding {
    padding-top: 50px;

    @include screen(false, lg) {
        padding-top: 40px;
    }

    @media screen and (max-width: 700px) {
        padding-top: 0;
    }
}

.article .article__header {
    margin: 0 0 50px;

    @include screen(false, lg) {
        margin-bottom: 30px;
    }

    figcaption {
        margin-top: 12px;
        color: palette(gray);
        text-align: center;
    }
}

.article__header-video-wrapper {
    max-width: 750px;
    margin: 0 auto;
    padding-top: 50px;

    @include screen(false, lg) {
        padding-top: 0;
    }
}

.article__header-inner {
    position: relative;

    img, picture {
        display: block;
        margin: 0 auto;
        width: 100%;
        height: auto;
    }
}

.article__header-link {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: $white;
    @include link-underline;
    font-weight: bold;
    text-shadow: 0 0 5px palette(black);

    &:visited {
        color: $white;
    }
}

.article__content {
    max-width: 880px;
    margin: auto;
    padding: 0 65px;

    @include screen(false, xxs) {
        padding: 0 10px;
    }

    h1 {
        margin-bottom: 10px;

        @include screen(false, xxs) {
            font-size: 24px;
        }
    }

    h2 {
        margin-top: 50px;
        margin-bottom: 20px;

        @include screen(false, lg) {
            margin-top: 40px;
        }

        @include screen(false, xxs) {
            margin-top: 30px;
        }
    }

    p {
        @include heading--like-h6;
        margin-bottom: 20px;

        @include screen(false, xxs) {
            font-size: 16px;
            line-height: 27px;
        }
    }

    a {
        font-weight: bold;
    }

    figure {
        margin: 50px auto;
        text-align: center;

        @include screen(false, lg) {
            margin: 40px 0 30px;
        }

        @include screen(false, xxs) {
            margin: 30px 0;
        }
    }

    figcaption {
        margin-top: 12px;
        color: palette(gray);
        text-align: center;
    }
}

.article__date {
    color: palette(gray);
    font-weight: bold;
    margin-bottom: 20px;
    display: inline-block;
    padding-right: 10px;
}

.article__author {
    color: palette(gray);
    margin-bottom: 20px;
    display: inline-block;
}

.article__inner {
    max-width: 910px;
    margin: auto;

    .advert__wrapper {
        width: 33.3%;

        @media screen and (max-width: 680px) {
            width: auto;
        }
    }

    .sm-link__share-label {
        @include screen(false, xxs) {
            display: none;
        }
    }

    .sm-link {
        @include screen(false, xxs) {
            justify-content: flex-start;
        }
    }

    .sm-link a {
        @include screen(false, xxs) {
            padding: 9px 4px;
            margin-left: 0;
            margin-right: 10px;
        }
    }
}

.article__tag {
    margin-right: 11px;
}

.article__video-tag {
    margin-right: 11px;
    font-size: 13px;
    line-height: 1.6;
    display: inline-block;
    color: #fff;
    background: #f79c3d;
    text-transform: uppercase;
    padding: 6px;
    font-weight: bold;
    z-index: 2;
}

.article__next-link {
    font-weight: bold;
    float: right;

    @media screen and (max-width: 720px) {
        margin-top: 20px;
        display: block;
        float: none;
    }
}

.article__next-icon {
    width: 12px;
    height: 15px;
    position: relative;
    top: 2px;
    padding-left: 3px;
    fill: palette(primary);
}

.article__nav {
    margin: 30px auto;
}

.article__back-link {
    // display: none;

    @media screen and (max-width: 700px) {
        display: block;
        padding: 10px 0;
        background: $white;
        text-align: center;
        width: 100%;
        margin: 0 20px;
        width: calc(100% - 40px);
        line-height: 21px;

        &:hover,
            &:focus {
            text-decoration: none;

            span {
                text-decoration: underline;
            }
        }
    }

    @include screen(false, xxs) {
        width: 100%;
        margin: 0;
    }

    .article__back-link-icon,
    span {
        display: none;

        @media screen and (max-width: 700px) {
            display: inline-block;
        }
    }
}

.article__back-link-icon {
    position: relative;
    top: 2px;
    margin-right: 5px;
    width: 8px;
    height: 14px;
    fill: palette(primary);
}

.article__close-icon__wrapper {
    background: palette(black, transparent);
    width: 58px;
    height: 58px;
    border-radius: 50%;
    position: absolute;
    right: 30px;
    top: 200px;
    z-index: 4;
    transition: background 0.3s ease;

    .article__back-link:hover &,
        .article__back-link:focus & {
        background: palette(black);
    }

    @media screen and (max-width: 700px) {
        display: none;
    }
}

.article__close-icon {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 50%;
    margin-left: -12px;
    fill: $white;

    @media screen and (max-width: 700px) {
        display: none;
    }
}
