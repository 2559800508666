.premium-company-profile-layout {
  .layout__main-column {
    & > .row:first-child {
      display: flex;

      @media (max-width: 700px) {
        flex-direction: column;
      }

      & > .column:nth-child(2) {
        min-width: 312px;

        @media (max-width: 700px) {
          width: 100%;
        }

        .company-info--show-large {

          @media (max-width: 1060px) {
            display: block;
          }

          @media (max-width: 700px) {
            width: 100%;
          }

          .company-info__col {

            @media (max-width: 1060px) {
              width: 100%;
            }
          }
        }
      }
    }

    div.column.mobile {
      width: 100%;
      display: block;

      .company-info {
        display: block;
      }
    }
  }

  .top-reports-section {

    .articles-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .thumbnail__wrapper--bigger {
        min-width: 220px;
        max-width: 25%;

        .thumbnail {
          .thumbnail__image {
            height: auto;
          }
        }

        .thumbnail--text_date {
          color: #a2a2a2;
          font-weight: bold;
          margin-bottom: 5px;
        }

        @media (max-width: 907px) {
          max-width: 100%;
        }

        @media (max-width: 463px) {
          width: 100%;
        }
      }
    }

    .more-news-link {
      float: right;
      position: relative;
      margin-right: 15px;
      color: #3da755;

      img {
        height: 15px;
        position: absolute;
        top: 5px;
        right: -15px;
        transition: .3s;
      }

      &:hover {
        img {
          right: -25px;
        }
      }
    }
  }

  .company-news {
    .articles-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .thumbnail__wrapper--bigger {
        min-width: 220px;
        max-width: 33.3%;

        .thumbnail {
          .thumbnail__image {
            height: auto;
          }
        }

        .thumbnail--text_date {
          color: #a2a2a2;
          font-weight: bold;
          margin-bottom: 5px;
        }

        @media (max-width: 997px) {
          max-width: 100%;
          flex: 1;
        }

        @media (max-width: 787px) {
          width: 100%;
        }
      }
    }

    .more-news-link {
      float: right;
      position: relative;
      margin-right: 15px;
      color: #3da755;

      img {
        height: 15px;
        position: absolute;
        top: 5px;
        right: -15px;
        transition: .3s;
      }

      &:hover {
        img {
          right: -25px;
        }
      }
    }
  }

  .product-overview {
    margin: 30px 0 0;

    h2 {
      font-size: 28px;
      line-height: 1.3em;
      width: 70%;
    }

    .product-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      .product-list-single {
        max-width: 25%;
        padding: 15px 15px;

        .thumbnail {
          height: 186px;
          text-align: center;
          overflow: hidden;

          @media (max-width: 1131px) {
            height: 160px;
          }

          &:before {
            display: none;
          }

          img {
            max-width: initial;
            height: 100%;
          }
        }

        .no-image {
          height: 186px;
          background: #eee url(../img/no-image/thumb/no-image.jpg) no-repeat center center;
          background-size: 100%;

          @media (max-width: 1131px) {
            height: 160px;
          }
        }

        h3 {
          margin: 25px 0;
          font-size: 1.4em;
        }

        p {

        }

        @media (max-width: 907px) {
          max-width: 100%;
          width: 33.3%;
          padding: 15px 15px;
        }

        @media (max-width: 768px) {
          width: 50%;
          padding: 15px 15px;
        }

        @media (max-width: 463px) {
          width: 100%;
          padding: 15px 15px;
        }
      }
    }
  }

  .company-contact {
    margin: 30px 0 0;

    h2 {
      font-size: 28px;
      line-height: 1.3em;
      width: 70%;
    }

    .cc-container {
      display: flex;
      flex-wrap: wrap;

      .cc-content {
        min-width: 25%;
        padding-left: 10px;

        &:first-child {
          padding-left: 0;
        }

        @media (max-width: 907px) {
          min-width: 33.3%;
        }

        @media (max-width: 768px) {
          min-width: 50%;
        }

        @media (max-width: 463px) {
          min-width: 100%;
          padding-left: 0;
          margin-bottom: 25px;
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .company-socials {
    margin: 30px 0;

    h2 {
      font-size: 28px;
      line-height: 1.3em;
      width: 70%;
    }

    .cs-container {
      a {
        display: inline-block;
        float: left;
        width: 50px;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .company-member-of {
    margin: 30px 0;

    h2 {
      font-size: 28px;
      line-height: 1.3em;
      width: 70%;
    }

    .cmo-container {
      span {
        display: inline-block;
        float: left;
        width: 150px;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  .company-gallery {
    margin: 30px 0;

    h2 {
      font-size: 28px;
      line-height: 1.3em;
      width: 70%;
    }

    .cg-container {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      justify-content: space-between;

      .cg-content {
        max-width: 32%;
        margin-bottom: 20px;
        min-width: 32%;

        @media (max-width: 907px) {
          min-width: 48%;
        }

        @media (max-width: 768px) {
          min-width: 100%;
        }

        .image-container {
          width: 100%;
          height: 250px;
          overflow: hidden;

          @media (max-width: 768px) {
            height: auto;
          }

          img {
            min-width: 100%;
            height: 100%;
            max-width: 300%;

            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        .image-description {
          margin: 10px 0 0 0;
        }
      }
    }
  }

  &.company-478 {
    .company-gallery .cg-container .cg-content {
      .image-container {
        text-align: center;

        img {
          min-width: auto;
          height: auto;
          max-height: 100%;
        }
      }
    }
  }

  &.company-fru {
    .product-overview {
      .product-list {
        .product-list-single {
          .thumbnail {
            display: flex;
            align-items: center;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

