.subscribe-page .subscribe-page__header {
    margin-top: 0;
}

.page-top-header {
    .heading {
        display: inline-block;
        font-weight: 300;
        font-size: 32px;
        line-height: 1.111111111;
        margin: 0 10px 0 0;

        span {
            font-size: 80%;
            display: block;
        }
    }

    .section-cols {
        @include screen(false, xxs) {
            display: block;

            h1 {
                font-weight: bold;
                word-break: normal !important;
                font-size: 120%;

                span {
                    font-weight: normal;
                }
            }

            .section-col2 {
                max-width: 100%;
                display: block;
                text-align: left;
                float: left;
                margin-top: 20px;
            }
        }

        .section-col1 {
            width: auto;

            h1 span {
                font-size: 80%;
                display: block;

                /*@include screen(false, lg) {
                    display: inline;
                    font-size: 100%;
                }*/
            }
        }

        .section-col2--with-dropdown {
            @include screen(false, lg) {
                width: 20%;
            }
            @include screen(false, xxs) {
                width: 100%;
            }
        }
    }

    .mobile-filter .links__wrapper .links {
        @include screen(false, xxs) {
            padding: 20px;
            margin: 0;
        }
    }
}

.page-top-navigation {
    .nav-links .nav-link {
        font-weight: 300;

        &.active {
            font-weight: 400;
        }
    }
}
