.advert-section {
    margin-top: 50px;

    @include screen(false, lg) {
        margin-top: 40px;
    }

    @include screen(false, xxs) {
        margin-top: 30px;
    }
}
