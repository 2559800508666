.top-report-image img {
    max-width: 210px;
}

.page-top-header {
    .section-cols {
        .links {
            a.active {
                font-weight: bold;
            }
        }
    }
}

.top-reports-section {
    .top-report-link {
        min-height: 360px;
    }
}

.report__image {
    width: 100%;
}

.reports-section {
    .section-cols {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: row wrap;
        align-content: flex-end;

        .section-col {
            float: none;
        }
    }

    &.seo-text-digimessenger {
        margin-top: 25px;
    }
}