// --------------------------
// tohle je podle styleguide, ale neni to konzistentni se zbytkem webu
// viz Press-Login ve sketchi
// --------------------------
.outline-tag {
    border: 2px solid $main-green;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: $main-green;
    letter-spacing: 0;
    padding: 7px 8px 8px;
    background: none;
    text-transform: uppercase;

    &:visited {
        color: $main-green;
    }

    &:hover,
    &:focus {
        @include transition();
        @include transition-property(background, border, color);
        background: $main-green;
        color: $white;
        text-decoration: none;
    }

    &:active {
        @include transition();
        @include transition-property(background, border);
        background: $dark-green;
        border-color: $dark-green;
        color: $white;
        text-decoration: none;
    }
}
