.sponsors-section {
    &.var-error404 {
        .logos {
            margin: 18px 15px 43px;
        }
    }

    + .page-footer {
        margin-top: 50px;

        @media screen and (max-width: 600px) {
            margin-top: 30px;
        }
    }
}
