.contactus-page {
    margin: 43px 0 -4px;

    @media screen and (max-width: 560px) {
        margin-top: 33px;
    }

    .contactus-page__header {
        text-align: center;
        margin: 46px 0;

        @media screen and (max-width: 560px) {
            margin: 33px 0 20px;
        }

        p {
            margin-top: 6px;
        }
    }

    .contactus-cols {
        width: 100%;
        max-width: 594px;
        margin: 0 auto;
        overflow: hidden;

        .contactus-col1 {
            width: 50%;
            float: left;
            padding-right: 15px;

            @media screen and (max-width: 560px) {
                float: none;
                width: auto;
                padding-right: 0;
                margin: 0 0 30px;
            }
        }
        .contactus-col2 {
            width: 50%;
            float: left;
            padding-left: 15px;
            margin-top: -4px;

            @media screen and (max-width: 560px) {
                float: none;
                width: auto;
                padding-left: 0;
                margin-top: 0;
            }
        }
    }

    .contactus-person {
        text-align: center;
        margin-bottom: 31px;
        line-height: 1.31;

        @media screen and (max-width: 560px) {
            margin-bottom: 26px;
        }

        .portrait {
            width: 100%;
            max-width: 192px;
            margin: 0 auto 19px;
            border: 1px solid #ccc;

            @media screen and (max-width: 560px) {
                margin-bottom: 10px;
            }

            @include screen(false, xxs) {
                max-width: 145px;
            }

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .name {
            font-size: 21px;
            font-weight: bold;
        }

        .position {
            color: $gray;
        }

        .mail {
            font-size: 21px;
            margin-top: 11px;
            font-weight: bold;
        }

        .phone {
            margin-top: 10px;

            a {
                color: $black;
                text-decoration: none;
            }
        }
    }

    .company-info {
        line-height: 1.31;
        text-align: center;

        .name {
            font-size: 21px;
            font-weight: bold;
        }
        .address {
            margin-top: 5px;
        }
    }

    .narrower {
        width: 100%;
        max-width: 252px;
        margin: 0 0 0 auto;

        @media screen and (max-width: 560px) {
            margin: 0 auto;
        }
    }

    .contactus-form {
        .form-row {
            margin-top: 0;
        }

        textarea {
            height: 220px;
        }

        .submit-row {
            //
        }

        .submit-button {
            display: block;
            width: 100%;
            max-width: 146px;
        }
    }
}
