.subscribe__text {
    @include screen(false, xxxs) {
        padding-right: 0;
    }
}

#newsletter-gdpr, #newsletter-agree {
    h2 {
        font-size: 120%;
        padding: 0.5em 0;
    }

    p {
        font-size: 80%;
        padding: 1em 0;
        margin: 0;
    }

    label {
        padding-left: 0.5em;
    }

    .gdpr-footer {
        a {
            float: left;
            padding: 0.7em 1em 1em 0;

            img {
                width: 50px;
            }
        }

        p {
            font-size: 70%;
        }
    }
}

#newsletter-agree {
    input {
        float: left;
    }

    label {
        display: inline-block;
        float: left;
        width: 95%;
        margin-top: -3px;

        @include screen(false, xxxs) {
            width: 90%;
        }
    }
}

.button-newsletter {
    line-height: 28px;
}
