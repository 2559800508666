.picture-thumbnail__wrapper {
    @include column;
    margin-bottom: 30px;
    padding-right: 5px;
    padding-left: 5px;


    @include screen(false, lg) {
        margin-bottom: 20px;
    }

    @include screen(false, xxs) {
        margin-bottom: 15px;
    }
}

.picture-thumbnail__wrapper--double {
    @include column;
    @extend .column-8-of-16;

    @include screen(false, lg) {
        @extend .column-8-of-12;
    }
}

.picture-thumbnail {
    position: relative;
    display: block;

    &:before {
        @include transition();
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    }

    &:hover,
    &:focus {
        &:before {
        @include transition();
            background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        }
    }
}

.picture-thumbnail__image {
    display: block;
    width: 300px;
}

.picture-thumbnail__tag {
    @include tag;
    top: 15px;
    left: 15px;
    position: absolute;
    z-index: 2;

    @include screen(false, xxxl) {
        top: 10px;
        left: 13px;
    }

    .picture-thumbnail__wrapper--double & {
        top: 20px;
        left: 20px;
    }
}

.picture-thumbnail__title {
    @include heading--like-h5;
    position: absolute;
    padding: 13px 15px;
    bottom: 0;
    color: $white;
    z-index: 2;
    display: inline-block;
    font-weight: bold;

    @include screen(false, xxxl) {
        @include heading--like-h6;
        line-height: 1.3;
        padding: 10px 13px;
    }

    .picture-thumbnail:hover &,
    .picture-thumbnail:focus & {
        span {
            @include link-underline;

            &:hover,
            &:focus {
                background-image: linear-gradient($transparent 50%, $white 50%);
            }
        }
    }

    .picture-thumbnail__wrapper--double & {
        @include heading--like-h2;
        padding: 30px 20px;
        line-height: 48px;

        @include screen(false, lg) {
            @include heading--like-h3;
        }
    }
}

.picture-thumbnail--text__title {
    @include heading--like-h5;
    font-weight: bold;
    margin: 10px 0 5px;
}

.picture-thumbnail--text__perex {
    margin-bottom: 0;
}
