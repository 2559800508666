.subscribe-page {
    margin: 43px 0;
    min-height: 52vh;

    .subscribe-page__header {
        text-align: center;
        margin: 46px 0 29px;

        p {
            margin-top: 6px;
        }
    }

    .subscribe-preferences-box {
        width: 100%;
        max-width: 576px;
        margin: 0 auto;
        border-top: 5px solid #ccc;
        background-color: $background-gray;
        padding: 23px 30px;

        fieldset {
            margin: 0;
            padding: 0;
            border: none;
            margin-bottom: 12px;
        }

        .multi-select {
            height: 50px;
            padding: 0;
            margin-bottom: 17px;

            option {
                height: 50px;
                padding: 14px;
            }
        }

        .form-row {
            margin: 4px 0;
        }

        .decorated-checkbox {
            margin-right: 27px;
            margin-top: 2px;
            margin-bottom: 10px;
        }

        .submit-row {
            margin: 15px 0;
            text-align: center;

            .button {
                display: block;
                margin: 0 auto;
                width: 100%;
                max-width: 267px;
            }
        }

        .copty-to-url {
            text-align: center;
            margin: 15px 0;

            .copy-link {
                display: block;
                margin-bottom: 3px;
            }

            input {
                display: block;
                width: 100%;
                max-width: 267px;
                margin: 0 auto;
            }

        }
    }

    .unsubscribe-box {
        width: 100%;
        max-width: 576px;
        margin: 0 auto;
        text-align: center;

        .change-prefrences-btn {
            display: block;
            width: 100%;
            max-width: 267px;
            margin: 0 auto;
        }

        .unsubscribe-link {

        }
    }
}
