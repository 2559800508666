.expo-box__wrapper {
    @include column;
    @extend .column-8-of-16;
    @extend .column-6-of-6;

    @include screen(false, sm) {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.expo-box__wrapper--large {
    @extend .column-16-of-16;
}

.expo-box {
    background: palette(gray, x-light);
    border-top: 5px solid palette(primary);
    padding: 30px 25px;

    @include screen(false, xxs) {
        padding: 30px 15px;
    }
}

.expo-box--large {
    padding: 30px 40px;
    margin: 0 15px;

    .expo-box__thumbnail {
        width: 25%;
    }

    @include screen(false, xxxl) {
        margin: 0 0px;
        padding: 30px 20px;

        .expo-box__thumbnail {
            width: 33.3%;
            &:nth-child(n+4) {
                display: none;
            }
        }
    }

    @include screen(false, sm) {

        .expo-box__thumbnail {
            width: 50%;
            &:nth-child(n+3) {
                display: none;
            }
        }
    }

    @include screen(false, xxs) {

        .expo-box__thumbnail {
            width: 100%;
            &:nth-child(n+2) {
                display: none;
            }

            img {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.expo-box__logo-wrapper,
.expo-box__sponsor {
    @include column;
    @extend .column-8-of-16;
    margin-bottom: 20px;
}

.expo-box__logo-wrapper {
    @media screen and (max-width: 1024px) {
        width: 100%;
        text-align: center;
    }

    .expo-box--large & {
        @media screen and (max-width: 1024px) {
            width: 50%;
            text-align: left;
        }

        @include screen(false, sm) {
            width: 100%;
            text-align: center;
        }
    }
}

.expo-box__sponsor {
    text-align: right;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    .expo-box--large & {
        @media screen and (max-width: 1024px) {
            display: block;
        }

        @include screen(false, sm) {
            display: none;
        }
    }
}

.expo-box__meta {
    margin-top: 15px;
    color: palette(gray);
}

.expo-box__thumbnail {
    @include column;
    @extend .column-4-of-16;

    .expo-box__wrapper & {
        @extend .column-8-of-16;

        @media screen and (max-width: 1024px) {
            width: 100%;
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    img {
        max-width: none;
        width: 100%;
    }
}


.expo-box__more-link {
    font-weight: bold;
    color: $black;
    display: block;
    text-align: center;
    margin-top: 20px;

    &:visited {
        color: $black;
    }
}

.expo-box__powered-by {
    @extend .powered-by__label;
}

.expo-box__sponsor--mobile {
    text-align: center;
    float: none;
    margin: 10px auto 0;
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }
}

.expo-box--large .expo-box__sponsor--mobile {
    text-align: center;
    float: none;
    margin: 10px auto 0;
    display: none;

    @include screen(false, sm) {
        display: block;
    }
}
