.thumbnail__wrapper {
    @include column;
    @extend .column-4-of-16;
    margin-bottom: 30px;


    @include screen(false, lg) {
        @extend .column-4-of-12;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 680px) {
        margin-bottom: 15px;
        width: 100%;
        float: none;
    }
}

.thumbnail__wrapper--thirds {
    width: 33.3%;

    @media screen and (max-width: 680px) {
        width: 100%;
    }
}

.thumbnail__wrapper--bigger {
    width: 33.3%;

    &:nth-child(3n+4) {
        clear: left;
    }

    @media screen and (max-width: 680px) {
        width: 100%;
    }
}

.thumbnail__wrapper--double {
    @include column;
    @extend .column-8-of-16;

    @include screen(false, lg) {
        @extend .column-8-of-12;
    }

    @media screen and (max-width: 680px) {
        width: 100%;
        float: none;
        margin-bottom: 15px;
    }
}

.thumbnail__wrapper--double--bigger {
    width: 66.6%;

    @media screen and (max-width: 680px) {
        width: 100%;
        float: none;
        margin-bottom: 15px;
    }
}

.thumbnail__link {
    color: $black;
}

.thumbnail {
    position: relative;
    display: block;

    &:before {
        @include transition();
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: rgba(0,0,0,0.4);
        background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    }

    .thumbnail__link:hover &,
    .thumbnail__link:focus & {
        &:before {
        @include transition();
            background-image: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        }
    }
}

.thumbnail__image {
    display: block;
    width: 100%;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.over-info {
    top: 15px;
    left: 15px;
    position: absolute;

    @include screen(false, xxxl) {
        top: 10px;
        left: 13px;
    }

    .thumbnail__wrapper--double & {
        top: 20px;
        left: 20px;
    }
}

.thumbnail__tag {
    @include tag;
    background: rgba(149, 148, 148, 0.63);
    z-index: 2;
    margin-bottom: 5px;

    .thumbnail__wrapper--double {
        top: 20px;
        left: 20px;
    }

    &.article {
        top: 20px;
        left: 20px;
        position: absolute;
    }
}

.thumbnail__title {
    @include heading--like-h5;
    position: absolute;
    padding: 13px 15px;
    bottom: 0;
    color: $white;
    z-index: 2;
    display: inline-block;
    font-weight: bold;

    @include screen(false, xxxl) {
        @include heading--like-h6;
        line-height: 1.3;
        padding: 10px 13px;
    }

    .thumbnail:hover &,
    .thumbnail:focus & {
        span {
            @include link-underline;

            &:hover,
            &:focus {
                background-image: linear-gradient($transparent 50%, $white 50%);
            }
        }
    }

    .thumbnail__wrapper--double & {
        @include heading--like-h2;
        padding: 30px 20px;
        line-height: 48px;

        @include screen(false, lg) {
            @include heading--like-h3;
        }

        @include screen(false, xxs) {
            @include heading--like-h5;
        }
    }
}

.thumbnail--text__title {
    @include heading--like-h5;
    font-weight: bold;
    margin: 10px 0 5px;
}

.thumbnail--text__perex {
    margin-bottom: 0;
}

#js-articles, .news-section {
    .thumbnail__wrapper--double .thumbnail .thumbnail__image {
        height: 100%!important;
        position: absolute;
        left: 0;
        top: 0;

    }

    .thumbnail__wrapper .thumbnail .thumbnail__image {
        height: 100%!important;
        position: absolute;
        left: 0;
        top: 0;
    }
}
