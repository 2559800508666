.slideshow__slide {
    float: left;
    text-align: center;
    width: 100%;
}

.slideshow__inner {
    position: relative;
}

.js-slider {
    position: relative;
    left: -10px;
}