.video-thumbnail__wrapper {
    @include column;
    @extend .column-4-of-16;
    margin-bottom: 30px;


    @include screen(false, lg) {
        @extend .column-4-of-12;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 680px) {
        margin-bottom: 15px;
        width: 100%;
        float: none;
    }
}

.video-thumbnail__section--main {
    .video-thumbnail__wrapper:first-child {
        width: 50%;

        @include screen(false, lg) {
            width: 66.66667%;
        }

        @media screen and (max-width: 680px) {
            width: 100%;
        }
    }

    .video-thumbnail__wrapper:nth-child(2) {
        width: 50%;

        @include screen(false, lg) {
            width: 33.33333%;

            .video-button {
                width: 35px;
                height: 35px;
                margin-top: -17px;
                margin-left: -17px;

                &:after {
                    left: 13px;
                    top: 10px;
                    border-width: 8px 0 8px 13px;
                }
            }
        }

        @media screen and (max-width: 680px) {
            width: 100%;
        }
    }

    .video-thumbnail__wrapper:last-child {

        @include screen(false, lg) {
            display: none;
        }
    }
}

.video-thumbnail__section {
    margin-top: 50px;

    @include screen(false, lg) {
        margin-top: 40px;
    }

    @include screen(false, xxs) {
        margin-top: 30px;
    }
}
