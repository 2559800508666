.loading-gif {
    // background-image: url(/assets/frontend/dist/img/loading.gif);
    background-image: url(../img/loading.gif);
    display: none;
    height: 24px;
    width: 24px;
}

.download-pdf-loading {
    @extend .loading-gif;
    margin-top: -6px;
    position: relative;
    top: 6px;
}

input[type=checkbox].error {
    border-color: $orange;
}
