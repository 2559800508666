.no-image {
    background: url('../img/no-image/thumb/no-image.jpg') $light-gray no-repeat left top;
}

.thumbnail__wrapper {
    &.without-mobile-thumbnail {
        .thumbnail {
            .no-image {
                @include screen(false, xxs) {
                    display: none;
                }
            }
        }
    }

    .thumbnail {
        .no-image {
            @extend .no-image;
            height: 185px;

            @include retina() {
                background-image: url('../img/no-image/thumb_retina/no-image.jpg');
                background-size: auto 197px;
            }
        }

        .thumbnail__image {
            img {
                max-width: unset;
            }

            height: 185px;

            @include screen(false, xxs) {
                height: 197px;
                overflow: hidden;
            }
        }

        overflow: hidden;
        height: 185px;

        @include screen(false, xxs) {
            height: 197px;
            overflow: hidden;
        }
    }
}

.thumbnail__wrapper--double {
    .thumbnail {
        .no-image {
            @extend .no-image;
            background-image: url('../img/no-image/thumb_big_d/no-image.jpg');
            height: 268px;

            @include retina() {
                background-image: url('../img/no-image/thumb_big_d_retina/no-image.jpg');
                background-size: auto 268px;
            }

            @media (min-width: 400px) {
                background-image: url('../img/no-image/thumb_big_m/no-image.jpg');
                height: 400px;
            }

            @media
                (min-width: 400px),
                (min--moz-device-pixel-ratio: 1.3),
                (-o-min-device-pixel-ratio: 2.6/2),
                (-webkit-min-device-pixel-ratio: 1.3),
                (min-device-pixel-ratio: 1.3),
                (min-resolution: 1.3dppx)
            {
                background-image: url('../img/no-image/thumb_big_m_retina/no-image.jpg');
                background-size: auto 400px;
            }
        }

        .thumbnail__image {
            height: 268px;

            @include screen(false, xxs) {
                height: 197px;
                overflow: hidden;
            }

            @media (min-width: 400px) {
                height: 400px;
            }
        }

        overflow: hidden;
        height: 268px;

        @include screen(false, xxs) {
            height: 197px;
            overflow: hidden;
        }

        @media (min-width: 400px) {
            height: 400px;
        }
    }
}

.expo-box__wrapper {
    .thumbnail {
        .no-image {
            @include screen(false, lg) {
                background-size: 290px auto;
                height: 186px;
            }
            @include screen(false, xxs) {
                background-size: 290px auto;
                height: 192px;
            }
            @media (min-width: 1020px) {
                height: 167px;
            }
        }
    }
}

.article__content.article-no-image {
    padding-top: 50px;
}
