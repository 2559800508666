.cta-section {
    margin-top: 50px;
    border-top: 5px solid palette(gray, light);
    background: palette(gray, x-light);
    padding: 50px 0;
    text-align: center;

    @include screen(false, xxs) {
        margin-top: 30px;
    }
}

.cta-section__text {
    @include heading--like-h5;
    font-weight: bold;
    margin-bottom: 15px;
}
