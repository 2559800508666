#usecookies {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    background: $background-gray;
    width: 100%;
    z-index: 1000;

    > div {
        width: 100%;
        padding: 20px;
        margin: 0 auto;

        p {
            display: inline;
        }

        @media only screen and (min-width: 1218px) {
            width: 1218px;

            p {
                float: left;
                max-width: 85%;
            }
        }

        a.button-blue {
            display: block;
            margin-top: 10px;
            text-align: center;

            @media only screen and (min-width: 1218px) {
                display: inline-block;
                float: right;
            }
        }
    }
}

#cookies-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 1102;
    padding: 2em;

    .title {
        @extend h4;
    }

    .buttons {
        margin-top: 2em;

        a {
            display: block;
            margin-top: 1em;

            &.disagree {
                background-color: $light-gray;
                color: $gray;
                border-color: $border-gray;

                &:hover {
                    background-color: $gray;
                    color: $white;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) {
        width: 640px;
        left: 50%;
        bottom: 40%;
        margin-left: -320px;
        border: 1px solid $border-gray;

        .buttons {
            text-align: right;

            a {
                display: inline-block;

                &.agree {
                    padding-left: 4em;
                    padding-right: 4em;
                    margin-right: 2em;
                }
            }
        }
    }
}

#cookies-overlay {
    background: $white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1101;
    opacity: .95;
}

.cookies-popup-close {
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    border-radius: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 2;
    border: none;
    display: block !important;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: 768px) {
        right: 45%;
    }

    &::before, &::after {
        background-color: #414141;
        content: '';
        position: absolute;
        left: 14px;
        height: 14px;
        top: 8px;
        width: 2px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}
