.photo-thumbnails-in-serp {
    @include clearfix;
    margin: 0 10px 36px;

    @include screen(false, lg) {
        margin: 0 5px 30px;
    }

    @include screen(false, xxs) {
        margin: 0 3px 30px;
    }
}
