.advertising-box {
    &.var-reports {
        @media screen and (max-width: 760px) {
            display: none;
        }
    }

    .box-title {
        font-size: 14px;
        text-align: right;
        color: #999;
        padding: 16px 0 14px;
    }

    .advertising-link {
        display: block;
        margin-bottom: 23px;
        color: $black;

        .advertising-link-title {
            display: block;
            font-size: 21px;
            line-height: 27px;
            font-weight: bold;

            @media screen and (max-width: 800px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
        .advertising-link-text {
            display: block;
            font-size: 16px;
            line-height: 21px;

            @media screen and (max-width: 800px) {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}
