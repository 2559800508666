.page-footer {
    .nav-section {
        .nav-links, .sm-link {
            height: auto;
        }
    }

    .copyright-section {
        .nav-links {
            @media screen and (min-width: 650px) {
                width: 100%;
            }

            @media screen and (min-width: 1200px) {
                width: 35%;
            }

            @include screen(false, xs) {
                display: block;

                a {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
