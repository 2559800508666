
/**
 _____   ____ __________  _____  _
|  __ \ / __ \___  / __ \|  __ \| |
| |__) | |  | | / / |  | | |__) | |
|  ___/| |  | |/ /| |  | |  _  /| |
| |    | |__| / /_| |__| | | \ \|_|
|_|     \____/_____\____/|_|  \_(_)

*/

/*
    Stylopis byl vygenerován preprocesorem.
    Nikdy needitujte přímo .css soubor!
    Viz readme.md
*/

