.sg-main-col {
    @include column;
    @extend .column-11-of-16;
}

.sg-sidebar {
    @include column;
    @extend .column-5-of-16;
}

.sg-layout-heading {
    margin-bottom: 20px;
    margin-top: 60px;
}

.sg-border {
    border: solid palette(gray, light);
    border-width: 0 0 1px 1px;
}
pre,
code {
    background: $black;
    color: $white;
    border-radius: 3px;
    padding: 3px;
}

pre {
    padding: 3px 20px;
}

// --------------------------
// Typography
// --------------------------

.sg-note {
    color: $muted-type;
    margin-top: 10px;
    margin-bottom: 40px;
}

.sg-link {
    margin-right: 10px;
}

.sg-link--hover {
    text-decoration: underline;
}

.sg-link--active {
    color: palette(primary, dark);
}

.sg-link--visited {
    color: palette(gray, dark);
}

.sg-black-link {
    margin-right: 10px;
    color: $black;
}

.sg-black-link--hover {
    text-decoration: underline;
}

.sg-black-link--active {
    color: palette(gray, dark);
}

.sg-black-link--visited {
    color: palette(gray, dark);
}

// --------------------------
// Colors
// --------------------------
.cf {
    @include clearfix();
}

.sg-color-sample {
    @include column;
    width: 25%;

    &:nth-child(5) {
        clear: left;
    }
}

.sg-color {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.sg-color--black {
    background: palette(black);
}

.sg-color--main-green {
    background: palette(primary);
}

.sg-color--dark-green {
    background: palette(primary, dark);
}

.sg-color--orange {
    background: palette(orange);
}

.sg-color--dark-gray {
    background: palette(gray, dark);
}

.sg-color--gray {
    background: palette(gray);
}

.sg-color--light-gray {
    background: palette(gray, light);
}

.sg-color--background-gray {
    background: palette(gray, x-light);
}

.sg-color-note {
    color: $muted-type;

    span {
        display: block;
    }
}

.sg-figure {
    @include column;
    width: 33.3%;
    display: block;
}




// --------------------------
// Layout
// --------------------------
[class^="c-"],
[class*=" c-"] {
    background: palette(white);
    color: palette(gray, dark);
    text-align: center;
    padding: 10px 0;

    p {
        margin: 0;
        background: palette(gray, x-light);
    }
}

.c-16-of-16 {
    @include column;
    @extend .column-16-of-16;
}

.c-10-of-16 {
    @include column;
    @extend .column-10-of-16
}

.c-8-of-16 {
    @include column;
    @extend .column-8-of-16;
}


.c-6-of-16 {
    @include column;
    @extend .column-6-of-16;
}

.c-4-of-16 {
    @include column;
    @extend .column-4-of-16;
}

.c-3-of-16 {
    @include column;
    @extend .column-3-of-16;
}

.c-2-of-16 {
    @include column;
    @extend .column-2-of-16;
}

.c-1-of-16 {
    @include column;
    @extend .column-1-of-16;
}

.c-12-of-12 {
    @include column;
    @extend .column-12-of-12
}


.c-6-of-12 {
    @include column;
    @extend .column-6-of-12;
}

.c-4-of-12 {
    @include column;
    @extend .column-4-of-12;
}

.c-3-of-12 {
    @include column;
    @extend .column-3-of-12;
}

.c-2-of-12 {
    @include column;
    @extend .column-2-of-12;
}

.c-1-of-12 {
    @include column;
    @extend .column-1-of-12;
}
