.report-success {
    text-align: center;
    font-weight: bold;
    color: $white;
    background-color: $dark-green;
}

.report-error {
    text-align: center;
    font-weight: bold;
    color: $white;
    background-color: $orange;
}