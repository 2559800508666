.news-section--text {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.company-info__links li a {
    font-weight: normal;
}

.news-column__row, .news-column__more-link {
    &:visited {
        color: $black;
    }
}