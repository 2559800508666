.plyr__progress--played, .plyr__volume--display {
    color: palette(primary)
}

.plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__controls button:hover {
    background-color: palette(primary);
}

.plyr__play-large {
    background-color: palette(black);
    border: 0;
    width: 140px;
    height: 140px;

    svg {
        width: 50px;
        height: 50px;
        left: 40px;
    }

    @include screen(false, xxs) {
        width: 70px;
        height: 70px;

        svg {
            width: 25px;
            height: 25px;
            left: 15px;
        }
    }
}
