.page-top-header {
    color: $white;
    background: palette(primary);
    padding: 23px 0 27px;

    @include screen(false, lg) {
        padding: 18px 0;
    }

    h1 {
        font-weight: 300;
        @include heading--like-h2;

        @include screen(false, lg) {
            font-size: 30px;
        }

        @media screen and (max-width: 600px) {
            font-size: 24px;
        }
    }

    a {
        color: #FFF;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .section-cols {

        margin: 0 -10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .no-flexbox & {
            overflow: hidden;
            display: block;
        }

        &.section-cols--with-dropdown {
            @media screen and (max-width: 1050px) {
                //display: block;
            }
        }

        .section-col1 {
            width: 35%;
            float: left;
            padding: 0 10px;
        }

        .section-col1--with-logo {
            flex-shrink: 1;
            @media screen and (max-width: 1050px) {
                display: block;
                width: 70%;
                max-width: none;
            }

            h1 {
                display: inline-block;
                margin-right: 10px;
            }

            .company-logo {
                position: relative;
                top: 4px;
            }
        }

        .section-col2 {
            max-width: 65%;
            float: right;
            padding: 0 10px;
            text-align: right;
            flex-shrink: 1;
        }

        .section-col2--with-dropdown {
            min-height: 43px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1050px) {
                display: block;
                width: 100%;
                max-width: none;
                min-height: 0;
                //height: 0;
            }
        }
    }

    .search-cols {
        overflow: hidden;
        margin: -4px -10px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 500px) {
            display: block;
        }

        .no-flexbox & {
            overflow: hidden;
        }

        .search-col1 {
            padding: 0 10px;
            margin-right: 10px;
            white-space: nowrap;
            flex-shrink: 0; //FLEXbug fix

            @media screen and (max-width: 500px) {
                width: 100%;
                margin-bottom: 10px;

            }

            .no-flexbox & {
                float: left;
                padding-top: 5px;

                @media screen and (max-width: 500px) {
                    float: none;
                }
            }
        }
        .search-col2 {
            width: 100%;
            max-width: 500px;
            padding-right: 10px;

            @media screen and (max-width: 500px) {
                width: 100%;
                padding-left: 10px;
            }

            .no-flexbox & {
                overflow: hidden;
                padding-left: 10px;
            }
        }
    }

    .mobile-filter__trigger {
        display: none;

        &:visited {
            color: white;
        }

        @media screen and (max-width: 1050px) {
            display: inline;
            position: relative;
            //top: -22px;

            &:hover,
            &:focus {
                text-decoration: none;

                span {
                    text-decoration: none;
                }
            }

            span {
                text-decoration: underline;
            }

            &.active {
                .mobile-filter__trigger-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .mobile-filter__trigger-icon {
        display: none;

        @media screen and (max-width: 1050px) {
            position: relative;
            top: -1px;
            margin-left: 4px;
            width: 14px;
            height: 8px;
            fill: $white;
            display: inline-block;
        }
    }

    .mobile-filter {
        //height: 0;
    }

    .mobile-filter .links__wrapper {
        @media screen and (max-width: 1050px) {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 9;

            .links {
                padding: 20px 30px 25px;
                background: palette(primary);
                display: none;

                &.is-open {
                    display: block;
                }

                .custom-select-box {
                    width: 100%;
                    display: block;
                    margin: 0 0 15px;
                    background-position: right 15px center;
                    z-index: 999;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {
            .links {
                padding: 20px 20px 15px;
            }
        }
    }


    .links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        margin: 0 -10px;

        .no-flexbox & {
            display: block;
        }

        .custom-select-box,
        a {
            display: inline-block;
            margin: 0 10px;
        }

        > a:visited {
            color: #fff;
        }
    }
}
