.slide__nav {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: block;
    width: 50px;
    height: 50px;
    background: palette(black, transparent);
    z-index: 4;
}

.slide__nav--prev {
    left: 0;

    &:hover,
    &:focus {
        .slide__nav-arrow {
            margin-left: -12px;
        }
    }
}

.slide__nav--next {
    right: 0;

    &:hover,
    &:focus {
        .slide__nav-arrow {
            margin-left: 0;
        }
    }
}

.slide__nav-arrow {
    @include transition();
    @include transition-property(margin-left);
    fill: $white;
    position: absolute;
    width: 12px;
    height: 20px;
    left: 50%;
    margin-left: -6px;
    top: 50%;
    margin-top: -10px;
}
