.advert__wrapper {
    @include column;
    @extend .column-4-of-16;
    margin-bottom: 30px;


    @include screen(false, lg) {
        margin-bottom: 20px;
        @extend .column-4-of-12;

        &:nth-child(n+4) {
            display: none;
        }
    }

    @media screen and (max-width: 680px) {
        margin-bottom: 15px;
        width: 100%;
        float: none;

        &:nth-child(n+3) {
            display: none;
        }
    }
}

.advert__wrapper--stacked {
    width: 100%;
    margin-bottom: 6px;
}

.advert__section-label {
    text-align: right;
    color: $gray;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 15px;
}
